import { CardHeader, Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { viewInstituteSurvey } from "../../../actions/instituteAction";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import { selectedLanguage } from "../../../variable/global";
import "../../dashboard/bibaran/institutionBibaran/bibran.css";
import { instituteFormName, instituteRoute } from "./config";

class InstitutionForm extends Component {
	state = {
		count: {},
		loading: true,
	};

	componentDidMount() {
		this.setState({ loading: true });
		let count = {};
		let body = instituteFormName;
		(async () => {
			let voidData = await Object.keys(body).map(
				(eachCat) =>
					new Promise((resolve, rej) => {
						body[eachCat].map((eachData) =>
							Axios.post(`/api/${eachCat}/count`, { category: eachData }).then(
								(res) => {
									resolve();
									count[eachData] = res.data.count;
								}
							)
						);
					})
			);
			await Promise.all(voidData);
			this.setState({
				count,
				loading: false,
			});
		})();
	}

	getCount = (route) => this.state.count[route.split("/").pop()];

	render() {
		const data = instituteRoute;
		const { classes } = this.props;
		const { loading } = this.state;
		return (
			<Grid container>
				{loading ? (
					<Grid container direction="row" justify="center" alignItems="center">
						<Grid item>
							<ClipLoader
								sizeUnit={"px"}
								size={84}
								color={"#00ACC1"}
								loading={loading}
							/>
						</Grid>
					</Grid>
				) : (
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="strech"
						style={{ display: "flex" }}
						spacing={24}
					>
						{data.map((each) => (
							<Grid item xs={4}>
								<Card>
									<CardHeader
										title={
											<span style={{ color: "white" }}>
												{each.title[selectedLanguage]}
											</span>
										}
										className={classes.cardHeader}
										avatar={<each.icon className={classes.icon} />}
									/>
									<Grid
										style={{ padding: "0px 10px" }}
										item
										container
										direction="row"
										justify="flex-start"
										alignItems="flex-start"
									>
										{each.child.map((sub, index) => (
											<Grid
												item
												container
												direction="row"
												justify="space-between"
												alignItems="stretch"
												className={classNames(
													classes.infoRow,
													index !== each.length - 1 && classes.border
												)}
												key={index}
											>
												<Grid item>
													<Link
														to={sub.route}
														style={{ textDecoration: "none" }}
													>
														<Typography className={classes.title}>
															{sub.name}
														</Typography>
													</Link>
												</Grid>
												<Grid item>
													<Typography
														variant="body1"
														style={{ marginRight: "10px" }}
													>
														{getNepaliNumber(this.getCount(sub.route) || 0)}
													</Typography>
												</Grid>
											</Grid>
										))}
									</Grid>
								</Card>
							</Grid>
						))}
					</Grid>
				)}
			</Grid>
		);
	}
}

const styles = (theme) => ({
	media: {
		height: "300px",
		width: "323px",
	},
	infoRow: {
		display: "flex",
		alignItems: "left",
		paddingTop: 10,
		paddingBottom: 10,
	},
	border: {
		borderBottom: "1px solid #ddd",
	},
	title: {
		fontSize: "1rem",
		fontWeight: 500,
		marginLeft: 10,
		marginRight: 10,
		cursor: "pointer",
		color: "#2196f3",
	},
	icon: {
		color: "white",
		float: "right",
	},
	cardHeader: {
		backgroundColor: "teal",
		color: "white",
	},
});

const mapStateToProps = ({ analysis: { loading, institute } }) => ({
	loading,
	institute,
});
export default withStyles(styles)(
	connect(mapStateToProps, { viewInstituteSurvey })(InstitutionForm)
);
