import {
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactToPrint from "../../common/frequent/print/ReactToPrint.jsx";
import PrintIcon from "@material-ui/icons/Print";
import getNepaliNumber from "get-nepali-number";
import React, { Component } from "react";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import { gaupalikaWard, selectedLanguage } from "../../../variable/global";

class reportHouseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beforePrint: false,
      component: null,
    };
  }

  handleAfterPrint = () => {
    this.setState({ beforePrint: false });
  };

  handleBeforePrint = () => {
    this.setState({ beforePrint: true });
  };

  getContent = () => {
    return this.setState(
      { beforePrint: true, component: this.componentRef },
      () => {
        return this.state.component;
      }
    );
  };
  getWard = (ward) => {
    if (ward === 0) {
      return gaupalikaWard[selectedLanguage];
    } else {
      return `वडा न. ${getNepaliNumber(ward)}`;
    }
  };
  render() {
    const { classes, data, ward } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ width: "auto" }}>
            {!this.props.beforePrint && (
              <CardHeader color="primary">
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="h6" className={classes.cardTitleWhite}>
                      {data.title || "Report"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {
                      <ReactToPrint
                        style={{ marginTop: 200 }}
                        onBeforePrint={this.handleBeforePrint}
                        onAfterPrint={this.handleAfterPrint}
                        trigger={() => (
                          <Fab
                            variant="extended"
                            aria-label="Delete"
                            className={classes.fab}
                          >
                            <PrintIcon className={classes.printIcon} />
                          </Fab>
                        )}
                        data={this.state.component}
                        content={this.getContent}
                      />
                    }
                  </Grid>
                </Grid>
              </CardHeader>
            )}

            <CardBody ref={(el) => (this.componentRef = el)}>
              {/* Table here */}
              {(this.state.beforePrint || this.props.beforePrint) && (
                <Typography variant="h6" className={classes.title}>
                  {data.title || "Report"}
                </Typography>
              )}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {data.reportTitle.map((header) => (
                      <TableCell>{header}</TableCell>
                    ))}

                    <TableCell>{this.getWard(ward)}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data &&
                    data.data.data.map((d, id) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          {d.label}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {getNepaliNumber(d.value)}
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell colspan={1}>जम्मा</TableCell>

                    <TableCell>
                      {getNepaliNumber(
                        data.data.data.reduce((c, a) => c + a.value, 0)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const styles = (theme) => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  root: {
    width: "auto",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: "auto",
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

export default withStyles(styles)(reportHouseTable);
