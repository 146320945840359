import {
	AppBar,
	Grid,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tabs,
	Typography
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { Skeleton } from "antd";
import classNames from "classnames";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import PropTypes from "prop-types";
import React from "react";
import { Pie } from "react-chartjs-2";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";
import {
	gaupalika,
	janasankhyaBibaranName,
	selectedLanguage
} from "../../../variable/global";
import Card from "../../common/Card/Card.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import CardIcon from "../../common/Card/CardIcon";
import "./fa.css";
import FamilyProgress from "./FamilyProgress";
var FontAwesome = require("react-fontawesome");
var { totalWard } = require("../../../config");

class PopulationInfo extends React.Component {
	state = {
		loadingAllWard: true,
		value: 0
	};
	componentDidMount() {
		loadCSS(
			"https://use.fontawesome.com/releases/v5.1.0/css/all.css",
			document.querySelector("#insertion-point-jss")
		);
	}
	getTitle = (ward, object) => {
		if (isEmpty(ward)) return object[0][selectedLanguage];
		else return object[ward.wardNumber][selectedLanguage];
	};

	// prepareStatistics = data => {
	// 	const { classes } = this.props;
	// 	return data.map((each, key) => (
	// 		<Grid
	// 			item
	// 			xl={2}
	// 			lg={3}
	// 			md={4}
	// 			sm={6}
	// 			xs={12}
	// 			key={key}
	// 			style={{ height: "100%" }}
	// 		>
	// 			<Card className={classes.card}>
	// 				<CardHeader color="warning" stats icon>
	// 					<CardIcon color="success">
	// 						{/* <Icon
	// 							className={classNames(
	// 								classes.white,
	// 								`fas fa-${each.icon}`,
	// 								classes.size
	// 							)}
	// 							color="action"
	// 						/> */}

	// 						<div>
	// 							<Typography className={this.props.classes.populationHeadline}>
	// 								{each[selectedLanguage]}
	// 							</Typography>
	// 						</div>
	// 					</CardIcon>
	// 					<CardBody style={{ paddingTop: 10 }}>
	// 						<h1 className={classes.cardCategory}>{each.count}</h1>
	// 						<h3 className={classes.cardTitle}>
	// 							{each.unit[selectedLanguage]}
	// 						</h3>
	// 					</CardBody>
	// 				</CardHeader>
	// 			</Card>
	// 		</Grid>
	// 	));
	// };

	getPieChart = () => {
		const bg = [
			"#00ACC1", //lim
			"#673ab7",
			"#f44336" //w1
		];
		const population = this.props.population.data;
		const label = [2, 3, 4].map(
			each =>
				population &&
				population[each] &&
				population[each].title[selectedLanguage]
		);
		const data = [2, 3, 4].map(
			each => population && population[each] && population[each].count
		);

		return (
			<Grid item md={4}>
				<Card chart color="info" style={{ height: "calc(100% - 60px)" }}>
					<CardHeader color="success" stats icon>
						<CardIcon color="success">
							<Typography className={this.props.classes.populationHeadline}>
								{this.getTitle(this.props.wardData, janasankhyaBibaranName)}
							</Typography>
						</CardIcon>
					</CardHeader>
					<Grid
						container
						direction="row"
						justify="center"
						alignItems="center"
						style={{ height: "100%" }}
					>
						<Grid item>
							{this.props.loading ? (
								this.getLoading(5)
							) : (
								<Pie
									width={250}
									height={250}
									type="pie"
									data={{
										labels: label,
										datasets: [
											{
												label: "संख्या",
												data: data,
												backgroundColor: bg,
												borderWidth: 2
											}
										]
									}}
									options={{
										scales: {}
									}}
								/>
							)}
						</Grid>
					</Grid>
				</Card>
			</Grid>
		);
	};

	getPopulation = () => {
		const { classes } = this.props;
		const population = this.props.population.data;
		const totalPopulation = population && population[1].count;
		const icon = ["home", "users", "male", "female", "user"];
		const bg = [
			"#00ACC1", //lim
			"#f44336", //w1
			"#ff5722",
			"#673ab7",
			"#e91e63",
			"#9c27b0"
		];

		return (
			<Grid item>
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-start"
				>
					{population &&
						population.map((eachPop, index) => (
							<Grid
								item
								container
								direction="row"
								justify="space-between"
								alignItems="flex-start"
								className={classNames(
									classes.infoRow,
									index !== population.length - 1 && classes.border
								)}
								key={index}
							>
								<Grid item>
									<Icon
										className={classNames(classes.icon, `fa fa-${icon[index]}`)}
										color="action"
									/>
								</Grid>

								<Grid item>
									<Typography className={classes.populationTitle}>
										{eachPop.title[selectedLanguage]}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body1" style={{ marginLeft: "auto" }}>
										{getNepaliNumber(eachPop.count)}
									</Typography>
								</Grid>
							</Grid>
						))}
				</Grid>
			</Grid>
		);
	};

	getMaxWard = allData =>
		allData.reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
	getMinWard = allData =>
		allData.reduce((iMax, x, i, arr) => (x < arr[iMax] ? i : iMax), 0);

	getCummulativeData = () => {
		const { classes } = this.props;
		// const loading = this.props.cummulativeLoading;
		const cummulative = this.props.cummulative;
		console.log("cummulative", cummulative);
		const icon = ["home", "users", "male", "female", "user"];
		// const eachWard = ["ward1", "ward2", "ward3", "ward4", "ward5"];
		return (
			<Grid item>
				<Grid
					item
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-start"
				>
					<Table style={{ width: "auto" }}>
						<TableHead>
							<TableRow>
								<TableCell
									component="th"
									scope="row"
									style={{ fontSize: "1.2rem", fontWeight: 500 }}
								>
									वडा
								</TableCell>
								<TableCell style={{ fontSize: "1.2rem", fontWeight: 500 }}>
									घर संख्या
								</TableCell>
								<TableCell style={{ fontSize: "1.2rem", fontWeight: 500 }}>
									पुरुष
								</TableCell>
								<TableCell style={{ fontSize: "1.2rem", fontWeight: 500 }}>
									महिला
								</TableCell>
								<TableCell style={{ fontSize: "1.2rem", fontWeight: 500 }}>
									तेस्रो लिङ्गी
								</TableCell>
								<TableCell style={{ fontSize: "1.2rem" }}>जनसंख्या</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{[...Array(totalWard).keys()].map(eachWard => (
								<TableRow key={eachWard}>
									<TableCell
										component="th"
										scope="row"
										style={{ fontSize: "1rem" }}
									>
										{`वडा ${getNepaliNumber(eachWard + 1)}`}
									</TableCell>
									{cummulative &&
										Object.keys(cummulative[`ward${eachWard + 1}`]).map(
											(eachCount, id) => (
												<TableCell
													key={id}
													style={{ width: "auto", fontSize: "1rem" }}
												>
													{getNepaliNumber(
														cummulative[`ward${eachWard + 1}`][eachCount]
													)}
												</TableCell>
											)
										)}
								</TableRow>
							))}

							{/* limchungbung ko janasankhya */}
							{cummulative && (
								<TableRow>
									<TableCell component="th" scope="row">
										{gaupalika[selectedLanguage]}
									</TableCell>
									{["house", "male", "female", "third", "total"].map(each => (
										<TableCell style={{ width: "auto", fontSize: "1rem" }}>
											{getNepaliNumber(
												[...Array(totalWard).keys()]
													.map(
														eachWard => cummulative[`ward${eachWard + 1}`][each]
													)
													.reduce((item, sum) => (sum += item))
											)}
										</TableCell>
									))}
								</TableRow>
							)}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		);
	};

	getLoading = count => {
		return (
			<Grid item>
				{[...Array(count).keys()].map(() => (
					<Skeleton avatar paragraph={{ rows: 0 }} />
				))}
			</Grid>
		);
	};

	handleChange = (event, value) => {
		this.setState({
			value: value
		});
	};

	getTabContent = () => {
		const { value } = this.state;
		const { cummulativeLoading, loading } = this.props;
		if (value === 0) {
			// if(this.props.isCummulativeFetched()) this.props.getCummulativePopulation();
			return loading ? this.getLoading(5) : this.getPopulation();
		} else if (value === 1) {
			if (!this.props.isCummulativeFetched())
				this.props.getCummulativePopulation();
			return cummulativeLoading
				? this.getLoading(5)
				: this.getCummulativeData();
		}
	};

	getCummulativePopulation = () => {
		const { classes } = this.props;
		const ward = parseInt(sessionStorage.getItem("ward") || 1);
		return (
			<Grid item md={8}>
				<div className={classes.root}>
					<AppBar position="static">
						<Tabs value={this.state.value} onChange={this.handleChange}>
							<Tab
								label={
									<Typography
										className={classNames(
											classes.populationHeadline,
											classes.tabHeadline
										)}
									>
										{this.getTitle(this.props.wardData, janasankhyaBibaranName)}
									</Typography>
								}
							/>
							<Tab
								label={
									<Typography
										className={classNames(
											classes.populationHeadline,
											classes.tabHeadline
										)}
									>
										समस्त
									</Typography>
								}
							/>
						</Tabs>
					</AppBar>
					{this.getTabContent()}
				</div>
			</Grid>
		);
	};

	// getCummulativePopulation = () => {
	// 	const { loading, classes, cummulativeLoading } = this.props;
	// 	return (
	// 		<Grid item md={8}>
	// 			<CustomTabs
	// 				title=""
	// 				headerColor="primary"
	// 				tabs={[
	// 					{
	// 						tabName: (
	// 							<Typography
	// 								className={classNames(
	// 									classes.populationHeadline,
	// 									classes.tabHeadline
	// 								)}
	// 							>
	// 								{this.getTitle(this.props.wardData, janasankhyaBibaranName)}
	// 							</Typography>
	// 						),
	// 						// tabIcon: ,
	// 						tabContent: loading ? this.getLoading(5) : this.getPopulation()
	// 					},
	// 					{
	// 						tabName: (
	// 							<Typography
	// 								className={classNames(
	// 									classes.populationHeadline,
	// 									classes.tabHeadline
	// 								)}
	// 							>
	// 								समस्त
	// 							</Typography>
	// 						),
	// 						// tabIcon: ,
	// 						tabContent: cummulativeLoading
	// 							? this.getLoading(5)
	// 							: this.getCummulativeData()
	// 					}
	// 				]}
	// 			/>
	// 		</Grid>
	// 	);
	// };

	render() {
		const { classes, loading } = this.props;
		const data = [
			{
				english: "Area",
				icon: "map-marked-alt",
				nepali: "Area",
				count: 121.56,
				unit: { english: "sq. km", nepali: "sq. km" }
			},
			{
				english: "Density",
				icon: "map-marked-alt",
				nepali: "Density",
				count: 80.54,
				unit: { english: "per sq. km", nepali: "per sq. km" }
			},
			{
				english: "Gender Ratio",
				icon: "map-marked-alt",
				nepali: "Gender Ratio",
				count: 89.43,
				unit: { english: "m:f", nepali: "m:f" }
			},
			{
				english: "Gender Ratio",
				icon: "map-marked-alt",
				nepali: "Gender Ratio",
				count: 89.43,
				unit: { english: "m:f", nepali: "m:f" }
			},
			{
				english: "Gender Ratio",
				icon: "map-marked-alt",
				nepali: "Gender Ratio",
				count: 89.43,
				unit: { english: "m:f", nepali: "m:f" }
			}
		];

		return (
			<div>
				<Grid container direction="column">
					<FamilyProgress
						expanded={this.props.expanded}
						handleExpanded={this.props.handleExpanded}
						wardData={this.props.wardData}
						progress={this.props.progress}
						loading={this.props.progressLoad}
						loadMore={this.props.loadMore}
					/>
					<Grid container spacing={8} style={{ marginTop: 20 }} direction="row">
						{/* {loading ? this.getLoading(5) : this.getPopulation()} */}
						{this.getCummulativePopulation()}
						{this.getPieChart()}
						{/* {loading ? (
							<Grid item md={4} style={{ padding: 20 }}>
								<Card
									chart
									color="info"
									style={{ height: "calc(100% - 60px)" }}
								>
									{this.getLoading(5)}
								</Card>
							</Grid>
						) : (
							this.getPieChart()
						)} */}
					</Grid>
				</Grid>
			</div>
		);
	}
}

PopulationInfo.propTypes = {
	classes: PropTypes.object.isRequired
};

const styles = theme => ({
	card: {
		paddingBottom: theme.spacing.unit * 2
	},
	white: {
		color: "#FFF"
	},
	size: {
		fontSize: "4px",
		width: 40,
		height: 40
	},
	infoRow: {
		display: "flex",
		alignItems: "center",
		paddingTop: 10,
		paddingBottom: 10
	},
	border: {
		borderBottom: "1px solid #ddd"
	},
	populationTitle: {
		fontSize: "1rem",
		color: "#575962",
		fontWeight: 500,
		marginLeft: 10,
		marginRight: 10
	},
	populationHeadline: {
		fontSize: "1rem",
		color: "#fff",
		fontWeight: 500
	},
	tabHeadline: {}
});
export default withStyles(styles)(PopulationInfo);
