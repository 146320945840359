import React, { Component } from "react";
import { Table, Divider, Button, Popconfirm, Icon } from "antd";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
// import Card from "../../common/Card/Card.jsx";
// import CardBody from "../../common/Card/CardBody.jsx";
import { Fab, Grid, Typography, Card } from "@material-ui/core";
import ReactToPrint from "../../common/frequent/print/ReactToPrint.jsx";
import PrintIcon from "@material-ui/icons/Print";
import withStyles from "@material-ui/core/styles/withStyles";

const { Column } = Table;

class table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			beforePrint: false,
			component: null,
		};
	}
	render() {
		const { classes, data, mapColumn } = this.props;
		return (
			<GridContainer>
				{/* <GridItem xs={12} sm={12} md={12}> */}
				<Card className={classes.card} fullwidth>
					{/* {!this.props.beforePrint && (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  {
                    <ReactToPrint
                      style={{ marginTop: 200 }}
                      onBeforePrint={this.handleBeforePrint}
                      onAfterPrint={this.handleAfterPrint}
                      trigger={() => (
                        <Fab
                          variant="extended"
                          aria-label="Delete"
                          className={classes.fab}
                        >
                          <PrintIcon className={classes.printIcon} />
                        </Fab>
                      )}
                      data={this.state.component}
                      content={this.getContent}
                    />
                  }
                </Grid>
              </Grid>
            )} */}
					<Grid ref={(el) => (this.componentRef = el)}>
						<Table
							style={{ width: "100%", margin: "0px 5px" }}
							dataSource={data}
							rowKey="uid"
							pagination={{
								defaultPageSize: 10,
								showSizeChanger: true,
								//   pageSizeOptions: ["10", "20", "30"]
							}}
						>
							<Column
								title={<span className="font-semibold">क्र.श</span>}
								dataIndex={"sn"}
								key={"sn"}
								render={(text, record, index) => <p>{index + 1}</p>}
							/>

							{mapColumn.map((column) => (
								<Column {...column} />
							))}
						</Table>
					</Grid>
				</Card>
				{/* </GridItem> */}
			</GridContainer>
		);
	}
}

const styles = (theme) => ({});

export default withStyles(styles)(table);
