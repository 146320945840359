import axios from "axios";
import { SAVE_HOUSE, SHOW_HOUSE } from "./types";
import { startSnackBar } from "../actions/utilAction";

export const addHouseSurvey = (data, isEdit) => (dispatch) => {
  axios.post(`/api/housesurvey/survey`, data).then((res) => {
    if (isEdit) {
      dispatch(
        startSnackBar({
          open: true,
          variant: "success",
          // message: response.data.message
          message: "घरको दाता सफलतापूर्वक अद्यावधिक गरियो",
        })
      );
    } else {
      dispatch(
        startSnackBar({
          open: true,
          variant: "success",
          // message: response.data.message
          message: "घरको नयाँ दाता सफलतापूर्वक अद्यावधिक गरियो",
        })
      );
    }

    dispatch({
      type: SAVE_HOUSE,
      payload: res.data,
    });
  });
};

export const editHouseSurvey = (id) => (dispatch) => {
  console.log("editHouseSurvey", id);
  axios
    .get(`/api/house/listhouse/${id}`)
    .then((res) => {
      dispatch({
        type: SHOW_HOUSE,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err, "ac"));
};
