import React, { Component } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core/es";
import withStyles from "@material-ui/core/styles/withStyles";
import FilterNone from "@material-ui/icons/FilterNone";
import VerticalSplit from "@material-ui/icons/VerticalSplit";
import { Divider } from "antd";
import classNames from "classnames";
import Popup from "reactjs-popup";
import DatasetOption from "./DatasetOption";
import BoundaryOption from "./BoundaryOption";
import InfrastructureOption from "./InfrastructureOption";
import DisasterOption from "./DisasterOption";
// import AnalyticOption from "./AnalyticOption";
// import FilterNone from "@material-ui/icons/FilterNone";
class Option extends Component {
	render() {
		const {
			classes,
			wardSelected,
			infastructureSelected,
			boundaryChecked,
			wardCheckAll,
			indeterminateWard,
			onCheckAllWardChange,
			handleWardChange,
			handleInfastructureChange,
			handleChange,
			otherCheckbox
		} = this.props;

		const options = [
			{
				name: "सिमाना",
				icon: FilterNone,
				menu: (
					<BoundaryOption
						otherCheckbox={otherCheckbox}
						boundaryChecked={boundaryChecked}
						wardCheckAll={wardCheckAll}
						indeterminateWard={indeterminateWard}
						onCheckAllWardChange={onCheckAllWardChange}
						infastructureSelected={infastructureSelected}
						wardSelected={wardSelected}
						handleChange={handleChange}
						handleWardChange={handleWardChange}
						handleInfastructureChange={handleInfastructureChange}
					/>
				),
				onClick: () => {}
			},
			{
				name: "आधारभूत संरचना",
				icon: VerticalSplit,
				menu: (
					<InfrastructureOption
						otherCheckbox={otherCheckbox}
						boundaryChecked={boundaryChecked}
						wardCheckAll={wardCheckAll}
						indeterminateWard={indeterminateWard}
						onCheckAllWardChange={onCheckAllWardChange}
						infastructureSelected={infastructureSelected}
						wardSelected={wardSelected}
						handleChange={handleChange}
						handleWardChange={handleWardChange}
						handleInfastructureChange={handleInfastructureChange}
					/>
				),
				dataToMenu: wardSelected,
				onClick: () => {}
			}
			// {
			// 	name: "प्राकृतिक प्रकोप",
			// 	icon: FilterNone,
			// 	menu: (
			// 		<DisasterOption
			// 		otherCheckbox={otherCheckbox}
			// 		boundaryChecked={boundaryChecked}
			// 		wardCheckAll={wardCheckAll}
			// 		indeterminateWard={indeterminateWard}
			// 		onCheckAllWardChange={onCheckAllWardChange}
			// 		infastructureSelected={infastructureSelected}
			// 		wardSelected={wardSelected}
			// 		handleChange={handleChange}
			// 		handleWardChange={handleWardChange}
			// 		handleInfastructureChange={handleInfastructureChange}
			// 		/>
			// 	),
			// 	dataToMenu: wardSelected,
			// 	onClick: () => {}
			// }
			// {
			// 	name: "प्राकृतिक प्रकोप",
			// 	icon: FilterNone,
			// 	menu: (
			// 		<DisasterOption
			// 		otherCheckbox={otherCheckbox}
			// 		boundaryChecked={boundaryChecked}
			// 		wardCheckAll={wardCheckAll}
			// 		indeterminateWard={indeterminateWard}
			// 		onCheckAllWardChange={onCheckAllWardChange}
			// 		infastructureSelected={infastructureSelected}
			// 		wardSelected={wardSelected}
			// 		handleChange={handleChange}
			// 		handleWardChange={handleWardChange}
			// 		handleInfastructureChange={handleInfastructureChange}
			// 		/>
			// 	),
			// 	dataToMenu: wardSelected,
			// 	onClick: () => {}
			// }
			// {
			// 	name: "Datasets",
			// 	icon: FilterNone,
			// 	menu: (
			// 		<DatasetOption
			// 			otherCheckbox={otherCheckbox}
			// 			boundaryChecked={boundaryChecked}
			// 			wardCheckAll={wardCheckAll}
			// 			indeterminateWard={indeterminateWard}
			// 			onCheckAllWardChange={onCheckAllWardChange}
			// 			infastructureSelected={infastructureSelected}
			// 			wardSelected={wardSelected}
			// 			handleChange={handleChange}
			// 			handleWardChange={handleWardChange}
			// 			handleInfastructureChange={handleInfastructureChange}
			// 		/>
			// 	),
			// 	onClick: () => {}
			// },
			// {
			// 	name: "Analytics",
			// 	menu: (
			// 		<DatasetOption
			// 			wardSelected={wardSelected}
			// 			handleWardChange={this.props.handleWardChange}
			// 		/>
			// 	),
			// 	dataToMenu: wardSelected,
			// 	icon: VerticalSplit,
			// 	onClick: () => {}
			// }
		];
		return (
			<Grid
				className={classes.container}
				container
				direction="column"
				justify="flex-start"
				// alignItems="center"
				alignItems="center"
			>
				{options.map((each, i) => {
					return (
						<Popup
							className={classes.popupStyle}
							trigger={
								<Grid
									item
									// onClick={each.onClick}
									className={classes.eachOption}
								>
									<each.icon
										className={classNames(classes.icon, classes.white)}
									/>
									<Typography
										variant="body2"
										className={classNames(classes.white, classes.label)}
									>
										{each.name}
									</Typography>
									{i + 1 !== options.length && <Divider />}
								</Grid>
							}
							position="left top"
							on="click"
							closeOnDocumentClick
							mouseLeaveDelay={30}
							mouseEnterDelay={0}
							contentStyle={{ padding: "0px", border: "none" }}
							arrow={false}
						>
							<div className={classes.popupContent}>{each.menu}</div>
						</Popup>
					);
				})}
			</Grid>
		);
	}
}

const styles = theme => ({
	icon: {
		fontSize: 30
	},
	container: {
		paddingTop: 10
	},
	white: {
		color: "#FFF"
	},
	eachOption: {
		cursor: "pointer",
		postion: "absoulute",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		width: "100%"
	},
	popupStyle: {
		postion: "absoulute",
		zIndex: 401
	},
	popupContent: {
		zIndex: 400
	},
	label: {
		marginTop: 5
	}
});
export default withStyles(styles)(Option);
