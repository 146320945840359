import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import analysisReducer from "./analysisReducer";
import searchReducer from "./searchReducer";
import houseSurvey from "./houseSurveyReducer";
import instituteReducer from "./instituteReducer";
import languageReducer from "./languageReducer";
import utilReducer from "./utilReducer";
import smsAnalysisReducer from "./smsAnalysisReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  analysis: analysisReducer,
  smsAnalysis: smsAnalysisReducer,
  search: searchReducer,
  houseSurvey: houseSurvey,
  instituteReducer: instituteReducer,
  language: languageReducer,
  util: utilReducer,
});
