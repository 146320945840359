import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import { green, lightBlue, red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Form, Input, Button } from "antd";
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteInstitute,
  viewInstituteSurvey,
} from "../../../actions/instituteAction";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";
import { selectedLanguage } from "../../../variable/global";
import { categoryList, suffixCategoryList } from "../../../variable/institute";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader.jsx";
import AlertDialog from "../../common/frequent/dialog/AlertDialog";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import TablePaginationActionsWrapped from "../search/death/TablePaginationActions";
import { instituteFormName } from "./config";

class InstitutionTable extends Component {
  state = {
    page: 0,
    rowsPerPage: 100,
    name: "",
    institute: {},
    deleteDialog: false,
    currentUserId: null,
    negButton: "",
    temp: {},
    posButton: "",
    open: false,
    searchName: "",
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleDeleteOpen = (id) => (event) => {
    this.setState({ deleteDialog: true, currentUserId: id });
  };

  handleClickOpen = () => {
    this.setState({ deleteDialog: true });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  removeInstitute = () => {
    const id = this.state.currentUserId;
    const category = this.props.category;
    let cat = "";
    if (instituteFormName.school.includes(category)) cat = "school";
    else if (instituteFormName.club.includes(category)) cat = "club";
    else if (instituteFormName.institute.includes(category)) cat = "institute";
    else if (instituteFormName.place.includes(category)) cat = "place";
    else if (instituteFormName.item.includes(category)) cat = "item";
    else if (instituteFormName.project.includes(category)) cat = "project";
    else if (instituteFormName.prodEducation.includes(category))
      cat = "prodEducation";
    deleteInstitute(cat, id).then((res) => {
      console.log(res);
      this.handleClose();
      this.fetchInstitution();
    });
  };

  filterData = () => {
    const { temp, searchName } = this.state;

    var institute = {};
    if (isEmpty(searchName)) institute = temp;
    else {
      institute.header = temp.header;
      institute.title = temp.title;
      institute.data = temp.data.filter((each) =>
        each[1].toString().includes(searchName)
      );
    }

    this.setState({ institute });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.filterData()
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.fetchInstitution();
    }
    if (prevProps.institute !== this.props.institute) {
      this.setState({
        institute: this.props.institute,
        temp: this.props.institute,
      });
    }
  }

  componentDidMount() {
    this.fetchInstitution();
  }

  getCategory = (category) => {
    let cat = "";
    if (instituteFormName.school.includes(category)) cat = "school";
    else if (instituteFormName.club.includes(category)) cat = "club";
    else if (instituteFormName.institute.includes(category)) cat = "institute";
    else if (instituteFormName.place.includes(category)) cat = "place";
    else if (instituteFormName.item.includes(category)) cat = "item";
    else if (instituteFormName.project.includes(category)) cat = "project";
    else if (instituteFormName.prodEducation.includes(category))
      cat = "prodEducation";

    return cat;
  };

  fetchInstitution = () => {
    const category = this.props.category;
    let cat = "";
    if (instituteFormName.school.includes(category)) cat = "school";
    else if (instituteFormName.club.includes(category)) cat = "club";
    else if (instituteFormName.institute.includes(category)) cat = "institute";
    else if (instituteFormName.place.includes(category)) cat = "place";
    else if (instituteFormName.item.includes(category)) cat = "item";
    else if (instituteFormName.project.includes(category)) cat = "project";
    else if (instituteFormName.prodEducation.includes(category))
      cat = "prodEducation";
    this.props.viewInstituteSurvey(category, cat);
  };

  render() {
    const { classes, category } = this.props;
    const { page, rowsPerPage, name, searchName, institute } = this.state;

    return (
      <div>
        <Paper className={classes.root} elevation={1}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{ width: "auto" }}>
                <CardHeader color="primary">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="h6"
                        className={classes.cardTitleWhite}
                      >
                        {`${
                          categoryList[
                            categoryList.findIndex(
                              (each) => each.value === category
                            )
                          ][selectedLanguage]
                        }${suffixCategoryList[selectedLanguage]}`}
                      </Typography>
                      <Form.Item>
                        <Input
                          name="searchName"
                          placeholder="search text"
                          onChange={this.handleChange}
                          value={searchName}
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item>
                      <Button type="primary">
                        <Link
                          to={`/survey-by-institution/report/${this.getCategory(
                            category
                          )}`}
                        >
                          report
                        </Link>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Link to={`/survey-by-institution/add/${category}`}>
                        <Fab
                          color="primary"
                          aria-label="Add"
                          className={classes.fab}
                        >
                          <AddIcon />
                        </Fab>
                      </Link>
                    </Grid>
                  </Grid>
                </CardHeader>
                <CardBody>
                  <AlertDialog
                    open={this.state.deleteDialog}
                    handleClose={this.handleClose}
                    negButtonClick={this.handleClose}
                    posButtonClick={this.removeInstitute}
                    title="Are you sure you want to delete the user ?"
                    posButton="agree"
                    negButton="disagree"
                  />
                  {/* Table here */}
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {institute.title &&
                          institute.title.map((data) => (
                            <TableCell
                              style={{
                                padding: "0px !important",
                                width: "auto",
                              }}
                            >
                              {data}
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {institute.data ? (
                        institute.data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((each, id) => {
                            return (
                              <TableRow key={id}>
                                <TableCell
                                  scope="row"
                                  style={{
                                    padding: "0px !important",
                                    width: "auto",
                                  }}
                                >
                                  {getNepaliNumber(
                                    page * rowsPerPage + (id + 1)
                                  )}
                                </TableCell>
                                {each &&
                                  [...Array(each.length - 1).keys()].map(
                                    (index) => (
                                      <TableCell
                                        style={{
                                          padding: "0px !important",
                                          width: "auto",
                                        }}
                                        // align="left"
                                      >
                                        {each[index + 1]}
                                      </TableCell>
                                    )
                                  )}
                                <TableCell
                                  style={{
                                    padding: "0px",
                                    width: "auto",
                                  }}
                                  align="left"
                                >
                                  <Link
                                    to={{
                                      pathname: `/survey-by-institution/edit/${category}/${each[0]}`,
                                      view: true,
                                    }}
                                  >
                                    <VisibilityIcon
                                      className={classnames(
                                        classes.preview,
                                        classes.icon
                                      )}
                                    />
                                  </Link>
                                  <Link
                                    to={{
                                      pathname: `/survey-by-institution/edit/${category}/${each[0]}`,
                                      view: false,
                                    }}
                                  >
                                    <Icon
                                      className={classnames(
                                        classes.edit,
                                        classes.icon
                                      )}
                                    />
                                  </Link>
                                  <DeleteIcon
                                    onClick={this.handleDeleteOpen(each[0])}
                                    className={classnames(
                                      classes.delete,
                                      classes.icon
                                    )}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>No data</TableRow>
                      )}
                    </TableBody>
                    {institute.data && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                            colSpan={7}
                            count={institute.data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              native: true,
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Paper>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  table: {
    // minWidth: 500,
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  icon: {
    margin: theme.spacing.unit * 1,
    cursor: "pointer",
    displayBlock: "inline",
  },
  preview: {
    color: lightBlue[500],
  },
  edit: {
    color: green[700],
  },
  delete: {
    color: red[500],
  },
  tableIcon: {
    display: "inlineFlex",
  },
});

const mapStateToProps = ({ instituteReducer: { institute } }) => ({
  institute,
});

export default withStyles(styles)(
  connect(mapStateToProps, { viewInstituteSurvey })(InstitutionTable)
);
