import { totalWard } from "../config.js";
import getNepaliNumber from "../utils/getNepaliNumber.js";
export const placeholderName = {
  nepali: "को विस्तृत  जानकारी खोज्नुहोस् जस्तै: लिङ्ग =पुरुष ",
  english: "'s advanced search, gender: male",
};
// export const languages = ["नेपाली", "English"];
// export const selectedLanguage = "nepali";

export const HouseSearchIndex = [
  "birthCertificate",
  "livingType",
  "childVaccine",
  "familyPlanning",
  "permanentLivingAddress",
  "waterSource",
  "cookingSource",
  "electricSource",
  "toilet",
  "wasteDisposal",
  "road",
  "bankAccount",
  "saltUsed",
  "yearSettled",
  "houseCount",
  "houseAge",
  "houseType",
  "roofType",
  "hasLandDocument",
  "doHouseCriteriaFullfill",
  "isHouseEarthquakeResisitance",
  "hasHouseMapPass",
  "totalIncome",
  "totalExpenditure",
  // "herb_herbBusinessName",
  "home_homeBusinessName",
  "item_title",
  "agricultureDetail_agricultureProductionSalesType",
  "agricultureProductionSalesType_crops",
  "agricultureProductionSalesType_daal",
  "agricultureProductionSalesType_oilseed",
  "agricultureProductionSalesType_vegetable",
  "agricultureProductionSalesType_fruit",
  "agricultureProductionSalesType_cash_crops",
  "detail_name",
  // "foreignCountry",
  // "abroadReason"
];

export const mapData = {
  house: [
    "wardNumber",
    "houseNumber",
    "oldVDC",
    "oldWardNumber",
    "maleNumber",
    "femaleNumber",
    "totalFamilyNumber",
    "livingType",
    "waterSource",
    "distanceToWaterSource",
    "cookingSource",
    "electricSource",
    "toilet",
    "wasteDisposal",
    "road",
    "bankAccount",
    "saltUsed",
    "yearSettled",
    "houseAge",
    "landUsedLiving",
    "houseType",
    "roofType",
    "hasLandDocument",
    "doHouseCriteriaFullfill",
    "isHouseEarthquakeResisitance",
    "hasHouseMapPass",
  ],
  family: [
    // "house",
    // "fullName",
    "gender",
    // "dateOfBirth",
    "age",
    "answererRelation",
    // "citizenshipNumber",
    // "citizenshipfront",
    // "citizenshipBack",
    // "contactNumber",
    "livingStatus",
    "ethnics",
    "caste",
    "religion",
    "motherTongue",
    "martialStatus",
    "healthCondition",
    "unhealthyDisease",
    "bloodGroup",
    "voterId",
  ],
  education: [
    "educationLevel",
    "prePrimaryDetails",
    "primaryDetails",
    "secondaryDetails",
  ],
  occupation: ["levelOneJob", "levelTwoJob", "levelThreeJob"],
  disable: ["isDisable", "type", "condition", "hasCard", "cardType"],
  abroad: ["abroadReason", "bideshiyekoYear", "foreignCountry"],
  death: [],
  insurance: [],
  houseFacility: ["item_title"],
  rent: [],
  land: [],
  agriculture: ["agricultureDetail_agricultureProductionSalesType"],
  agricultureDetail: [
    "agricultureProductionSalesType_crops",
    "agricultureProductionSalesType_daal",
    "agricultureProductionSalesType_oilseed",
    "agragricultureProductionSalesType_vegetable",
    "agricultureProductionSalesType_fruit",
    "agricultureProductionSalesType_cash_crops",
  ],
  liveStock: ["detail_name"],
  beeFishSilk: [], //contains nested fields to be searched
  economics: ["totalIncome", "totalExpenditure"],
  skill: ["detail"],
  business: ["home_homeBusinessName"],
  // business: ["home_homeBusinessName", "herb_herbBusinessName"],

  womenChild: [
    "birthCertificate",
    "childVaccine",
    "familyPlanning",
    "deliveryFromDoctor",
    "pregnantNutrition",
    "pregnanctCheck",
    "childNutrition",
    "childLabour",
    "earlyMarriage",
    "pregnantTetanus",
  ],
  welfare: [],
};

export const isBoolean = [
  "hasCard",
  "birthCertificate",
  "childVaccine",
  "familyPlanning",
  "deliveryFromDoctor",
  "pregnantNutrition",
  "pregnanctCheck",
  "childNutrition",
  "childLabour",
  "earlyMarriage",
  "pregnantTetanus",
  "bankAccount",
];
export const booleanAttribute = {
  hasCard: "card_taken",
  birthCertificate: "birth_cert_yes",
  childVaccine: "vaccaine_yes",
  familyPlanning: "yes",
  deliveryFromDoctor: "delivery_yes",
  pregnantNutrition: "nutrition_yes",
  pregnanctCheck: "check_yes",
  childNutrition: "mal_nutri_yes",
  childLabour: "yes",
  earlyMarriage: "e_marry_yes",
  pregnantTetanus: "tetanus_yes",
  bankAccount: "account_yes",
};

export const isInteger = ["wardNumber"];
export const nestedArray = [
  "agricultureDetail_agricultureProductionSalesType",
  "detail_name",
  "item_title",
];
export const rangeValue = ["age", "totalIncome", "totalExpenditure"];

export const searchTableHeader = [
  "क्र.स ",
  "वडा न.",
  "घर न.",
  "मृत्यु हुनेको नाम थर",
  "लिङ्ग",
  "उमेर ",
  "मृत्युको कारण ",
];

export const genderList = [
  { nepali: "पुरुष", english: "Male", value: "male" },
  { nepali: "महिला", english: "Female", value: "female" },
  { nepali: "तेस्रो लिङ्गी", english: "Third Gender", value: "third_gender" },
];

export const casteList = [
  { nepali: "ब्राहमण/क्षेत्री", english: "", value: "brahmin_chetri" },
  { nepali: "भोटे", english: "", value: "bhote" },
  { nepali: "जनजाती आदिवासी", english: "", value: "jajati_aadiwas" },
  // { nepali: "पहाडी अन्य जाति", english: "", value: "pahadi_other" },
  // {
  // 	nepali: "मधेसी जनजाति तथा आदिबासी ब्राम्हण तथा राजपुत दलित",
  // 	english: "",
  // 	value: "madhesi_jajati"
  // },
  // { nepali: "मधेसी ब्राम्हण तथा राजपुत", english: "", value: "madhesi_bramin" },
  { nepali: "मधेसी दलित", english: "", value: "madhesi_dalit" },
  { nepali: "परियार (दमाई,दर्जी,सूचिका)", english: "", value: "pariyar" },
  {
    nepali: "विश्वकर्मा (कामी,लोहार,ओड,दर्जी चुनरा,पार्की)",
    english: "",
    value: "biswokarma",
  },
  { nepali: "सार्की (मिजार,चर्माकार मूल)", english: "", value: "sarki" },
  // { nepali: "अल्पशंख्यक जाति (हायु,बोटे)", english: "", value: "hayu_bote" },
  { nepali: "ठकुरी", english: "", value: "thakuri" },
  { nepali: "माझी", english: "", value: "majhi" },
  { nepali: "मुस्लिम", english: "", value: "muslim" },
  { nepali: "अन्य", english: "", value: "other_caste" },
];

export const religionList = [
  { nepali: "हिन्दु", english: "", value: "hindu" },
  { nepali: "बुद्ध", english: "", value: "buddhist" },
  { nepali: "इस्लाम (मुस्लिम)", english: "", value: "muslim" },
  { nepali: "इसाई (क्रिश्चियन)", english: "", value: "christian" },
  { nepali: "किंरात", english: "", value: "kirat" },
  { nepali: "अन्य", english: "", value: "other_religion" },
];

export const educationList = [
  {
    nepali: "बाल विकास कक्षा",
    english: "kinder Garden",
    value: "kinder_garten",
  },
  {
    nepali: "पूर्व प्राथमिक",
    english: "Pre Pimary",
    value: "pre_primary",
  },
  {
    nepali: "आधारभूत तह",
    english: "Primary",
    value: "primary",
  },
  {
    nepali: "माध्यमिक तह",
    english: "Secondary",
    value: "secondary",
  },
  {
    nepali: "प्राविधिक एस.एल.सी. (Overseer)",
    english: "T SLC",
    value: "t_slc",
  },
  {
    nepali: "स्नातक तह",
    english: "Bachelors",
    value: "bachelors",
  },
  {
    nepali: "स्नातककोत्तर तह",
    english: "Masters",
    value: "masters",
  },
  {
    nepali: "एमफिल",
    english: "M Phil",
    value: "m_phil",
  },
  {
    nepali: "विधा बारिधि",
    english: "Phd",
    value: "phd",
  },
  {
    nepali: "साधारण लेखपढ((साक्षर)",
    english: "Literate",
    value: "literate",
  },
  {
    nepali: "निरक्षर (लेखपढ गर्न नसक्ने)",
    english: "Illiterate",
    value: "illiterate",
  },
  {
    nepali: "बिद्यालय शिक्षा हाशिल नगरेको",
    english: "No school",
    value: "no_school",
  },
];

export const preprimaryList = [
  {
    nepali: "१",
    english: "1",
    value: "class_1",
  },
  {
    nepali: "२",
    english: "2",
    value: "class_2",
  },
  {
    nepali: "३",
    english: "3",
    value: "class_3",
  },
  {
    nepali: "४",
    english: "4",
    value: "class_4",
  },
  {
    nepali: "५",
    english: "5",
    value: "class_5",
  },
];

export const primaryList = [
  {
    nepali: "६",
    english: "6",
    value: "class_6",
  },
  {
    nepali: "७",
    english: "7",
    value: "class_7",
  },
  {
    nepali: "८",
    english: "8",
    value: "class_8",
  },
];

export const secondaryList = [
  {
    nepali: "९",
    english: "9",
    value: "class_9",
  },
  {
    nepali: "१०",
    english: "10",
    value: "class_10",
  },
  {
    nepali: "११",
    english: "11",
    value: "class_11",
  },
  {
    nepali: "१२",
    english: "12",
    value: "class_12",
  },
];

export const occupationList = [
  {
    nepali: "कृषि तथा पशुपालन",
    english: "Agriculture",
    value: "agriculture",
  },
  {
    nepali: "नोकरी जागिर",
    english: "Job",
    value: "job",
  },
  // {
  // 	nepali: "उद्योग व्यापार",
  // 	english: "Industry",
  // 	value: "industry"
  // },
  {
    nepali: "व्यवसायिक कार्य",
    english: "Business",
    value: "business",
  },
  {
    nepali: "ज्याला मजदुरी",
    english: "Labor",
    value: "labor",
  },
  {
    nepali: "विद्यार्थी (अध्यनरत)",
    english: "Student",
    value: "student",
  },
  {
    nepali: "गृहणी",
    english: "House wife",
    value: "house_wife",
  },
  {
    nepali: "बैदेशिक रोजगार",
    english: "Foreign Job",
    value: "foreign_job",
  },
  {
    nepali: "बेरोजगार",
    english: "Unemployed",
    value: "unemployed",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];

export const jobList = [
  {
    nepali: "प्राइभेट",
    english: "Private",
    value: "private",
  },
  {
    nepali: "सरकारी (निजामती कर्मचारी )",
    english: "Government",
    value: "government",
  },
  {
    nepali: "सरकारी (निजामती बाहेक )",
    english: "Government1",
    value: "government1",
  },
  {
    nepali: "निर्माण सम्बन्धी",
    english: "Construction",
    value: "construction",
  },
  {
    nepali: "सुरक्षा निकाय",
    english: "Security",
    value: "security",
  },
  {
    nepali: "N.G.O.  I.N.G.O संघ संस्था",
    english: "N.G.O I.N.G.O",
    value: "ngo_ingo",
  },
  {
    nepali: "बैंक तथा वित्तिय संस्था",
    english: "Bank Finance",
    value: "bank_finance",
  },
  {
    nepali: "चिकित्सक तथा स्वास्थ कर्मी",
    english: "Health",
    value: "health",
  },
  {
    nepali: "शिक्षक",
    english: "Teacher",
    value: "teacher",
  },
  {
    nepali: "पत्रकार",
    english: "Journalist",
    value: "journalist",
  },
  {
    nepali: "वकिल",
    english: "Advocate",
    value: "advocate",
  },
  {
    nepali: "परामर्श",
    english: "Consulting",
    value: "consulting",
  },
  {
    nepali: "ठेक्का  पट्टा",
    english: "Contract",
    value: "contract",
  },
  {
    nepali: "पुजारी",
    english: "Piest",
    value: "priest",
  },
  {
    nepali: "किराना तथा थोक",
    english: "Grocery",
    value: "grocery",
  },
  {
    nepali: "होटेल / लज",
    english: "Hotel",
    value: "hotel",
  },
  {
    nepali: "घरेलु उद्योग",
    english: "Home Industry",
    value: "home_industry",
  },
  {
    nepali: "मासु पसल",
    english: "Meat shop",
    value: "meat_shop",
  },
  {
    nepali: "तरकारी / फलफुल",
    english: "Vegetable",
    value: "vege_fruit",
  },
  {
    nepali: "सिलाई बुनाई",
    english: "Sewing",
    value: "sewing",
  },
  {
    nepali: "फलाम जन्य कार्य गर्ने",
    english: "Iron",
    value: "iron",
  },
  {
    nepali: "हस्तकला सम्बन्धी",
    english: "Handicraft",
    value: "handicraft",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const healthconditionList = [
  {
    nepali: "स्वास्थ्य",
    english: "Health",
    value: "helathy",
  },
  {
    nepali: "सामान्य रोग लागेको",
    english: "Normal Patient",
    value: "normal_patient",
  },
  {
    nepali: "दिर्घ रोग लागेको",
    english: "Unhealthy",
    value: "unhelathy",
  },
  {
    nepali: "मानसिक समस्या भएको",
    english: "Mental",
    value: "mental",
  },
  {
    nepali: "क्यान्सर रोग",
    english: "Cancer",
    value: "cancer",
  },
];

export const unhealthy = [
  {
    nepali: "उच्च रक्तचाप",
    english: "High Blood Pressure",
    value: "high_blood_pre",
  },
  {
    nepali: "मुटु रोग",
    english: "Heart",
    value: "heart",
  },
  {
    nepali: "डाइबेटिज/मधुमेह/सुगर",
    english: "Diabetes",
    value: "diabetes",
  },
  {
    nepali: "आस्थमा/दम",
    english: "Asthama",
    value: "aasthama",
  },
  {
    nepali: "मृगोला सम्बन्धी",
    english: "Liver",
    value: "liver",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const teacherList = [
  {
    nepali: "स्थानीय श्रोत",
    english: "Local",
    value: "local",
  },
  {
    nepali: "सामुदायिक",
    english: "Government",
    value: "government",
  },
  {
    nepali: "राहत",
    english: "Rahat",
    value: "rahat",
  },
  {
    nepali: "नेपाल प्रहरी",
    english: "Nepal Police",
    value: "nepal_police",
  },
  {
    nepali: "सशस्त्र प्रहरी",
    english: "A P F",
    value: "a_p_f",
  },
  {
    nepali: "नेपाली सेना",
    english: "Nepal Army",
    value: "nepal_army",
  },
];
export const mentaltype = [
  {
    nepali: "जन्मजात",
    english: "By birth",
    value: "by_birth",
  },
  {
    nepali: "रोग",
    english: "Disease",
    value: "disease",
  },
  {
    nepali: "दुर्घटना",
    english: "Accident",
    value: "accident",
  },
];

export const mentalcondition = [
  {
    nepali: "दृष्टि सम्बन्धी",
    english: "Vision",
    value: "vision",
  },
  {
    nepali: "सुनाइ सम्बन्धी ",
    english: "Listening",
    value: "listening",
  },
  {
    nepali: "शारिरिक अपाङ्गता",
    english: "Disability",
    value: "disability",
  },
  {
    nepali: "बोलाइ सम्बन्धी ",
    english: "speak",
    value: "speak",
  },
  {
    nepali: "मानसिक रुपमा अपाङ्गता",
    english: "Mental",
    value: "mental",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const mentalCardYesNo = [
  {
    nepali: "छ",
    english: "yes",
    value: "card_taken",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "card_not_taken",
  },
];

export const mentalCardtype = [
  {
    nepali: "रातो (पूर्ण असक्त) क वर्ग",
    english: "Red Card",
    value: "red_card",
  },
  {
    nepali: "निलो (अरुको सहयोगबाट) ख वर्ग",
    english: "Blue card",
    value: "blue_card",
  },
  {
    nepali: "पहेलो (कृतिम अंगको ) ग वर्ग",
    english: "Yellow card",
    value: "yellow_card",
  },
  {
    nepali: "सेतो (सामान्य) घ वर्ग्ग",
    english: "White card",
    value: "white_card",
  },
];

export const mothertongue = [
  {
    nepali: "नेपाली",
    english: "Nepali",
    value: "nepali",
  },
  {
    nepali: "राई",
    english: "Rai",
    value: "rai",
  },
  {
    nepali: "किरात",
    english: "Kirat",
    value: "kirat",
  },
  {
    nepali: "मगर",
    english: "Magar",
    value: "magar",
  },
  {
    nepali: "तामाङ",
    english: "Tamang",
    value: "tamang",
  },
  {
    nepali: "नेपाल भाषा (नेवारी)",
    english: "Newari",
    value: "newari",
  },
  {
    nepali: "गुरुङ",
    english: "Gurung",
    value: "gurung",
  },
  {
    nepali: "लिम्बु",
    english: "Limbu",
    value: "limbu",
  },
  {
    nepali: "शेर्पा",
    english: "Sherpa",
    value: "sherpa",
  },
  {
    nepali: "मैथिली",
    english: "Maithali",
    value: "maithali",
  },
  {
    nepali: "थारु",
    english: "Tharu",
    value: "tharu",
  },
  // {
  // 	nepali: "खस",
  // 	english: "Khas",
  // 	value: "khas"
  // },
  // {
  // 	nepali: "भोजपुरी",
  // 	english: "Bhojpuri",
  // 	value: "bhojpuri"
  // },
  {
    nepali: "माझी",
    english: "Maajhi",
    value: "maajhi",
  },
  {
    nepali: "भोटे",
    english: "Bhote",
    value: "bhote",
  },
  // {
  // 	nepali: "बज्जिका",
  // 	english: "Bajjika",
  // 	value: "bajjika"
  // },
  {
    nepali: "सुनवार",
    english: "Sunwar",
    value: "sunwar",
  },
  {
    nepali: "बान्तवा",
    english: "Bantawa",
    value: "bantawa",
  },
  {
    nepali: "खालिंङ",
    english: "Khaling",
    value: "khaling",
  },
  {
    nepali: "थुलुंग",
    english: "Thulung",
    value: "thulung",
  },
  {
    nepali: "कुलुंग",
    english: "Kulung",
    value: "kulung",
  },
  {
    nepali: "याक्खा",
    english: "Yakha",
    value: "yakha",
  },
  {
    nepali: "चामलिंङ",
    english: "Chamling",
    value: "chamling",
  },
  {
    nepali: "वादीङ",
    english: "Wading",
    value: "wading",
  },
  {
    nepali: "तिलुंङ",
    english: "Tilung",
    value: "tilung",
  },
  {
    nepali: "वाम्वुले",
    english: "bambule",
    value: "bambule",
  },
];

export const bloodgroup = [
  {
    nepali: "O+",
    english: "O positive",
    value: "o_positive",
  },
  {
    nepali: "O-",
    english: "O Negative",
    value: "o_negative",
  },
  {
    nepali: "A+",
    english: "A Positive",
    value: "a_positive",
  },
  {
    nepali: "A-",
    english: "A negative",
    value: "a_neagtive",
  },
  {
    nepali: "B+",
    english: "",
    value: "b_positive",
  },
  {
    nepali: "B-",
    english: "",
    value: "b_negative",
  },
  {
    nepali: "AB+",
    english: "",
    value: "ab_positive",
  },
  {
    nepali: "AB-",
    english: "",
    value: "ab_neagtive",
  },
  {
    nepali: "थाहा छैन",
    english: "",
    value: "no_blood_group",
  },
];

export const marriage = [
  {
    nepali: "अविवाहित",
    english: "Unmarried",
    value: "unmarried",
  },
  {
    nepali: "विवाहित",
    english: "Married",
    value: "married",
  },
  {
    nepali: "बहु विवाह",
    english: "multi marriage",
    value: "multi_marriage",
  },
  {
    nepali: "पुन विवाह",
    english: "Re marriage",
    value: "re_married",
  },
  {
    nepali: "बिधुर",
    english: "Widow",
    value: "widow",
  },
  {
    nepali: "बिधुवा",
    english: "widow",
    value: "wido1",
  },
  {
    nepali: "सम्बन्ध बिच्छेद (विवाहित तर अलग बसेको)",
    english: "Divorce",
    value: "divorce",
  },
  {
    nepali: "उमेर कम",
    english: "Under age",
    value: "under_age",
  },
];
export const livingTypeList = [
  {
    nepali: "अस्थाई",
    english: "Temporary",
    value: "temporary",
  },
  {
    nepali: "स्थाई",
    english: "permanent",
    value: "permanent",
  },
  {
    nepali: "सुकुम्बासी",
    english: "squatter",
    value: "squatter",
  },
];
export const permanent = [
  {
    nepali: "अर्को वडा",
    english: "Next ward",
    value: "next_ward",
  },
  {
    nepali: "यही जिल्ला",
    english: "same district",
    value: "same_district",
  },
  {
    nepali: "अर्को जिल्ला",
    english: "next district",
    value: "next_district",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const drinkingwaterLists = [
  {
    nepali: "पाइप धारा घरमा",
    english: "pipe in home",
    value: "pipe_in_home",
  },
  {
    nepali: "पाइप धारा सार्वजनिक",
    english: "public pipe",
    value: "public_pipe",
  },
  {
    nepali: "डिप बोरिंग",
    english: "boring",
    value: "boring",
  },
  {
    nepali: "ट्युबवेल/हेन्डपम्पा",
    english: "hand tube pump",
    value: "hand_tube_pump",
  },
  {
    nepali: "ढाकिएको इनार/कुवाा",
    english: "well",
    value: "well",
  },
  {
    nepali: "नढाकिएको इनार/कुव",
    english: "mulko pani",
    value: "mulko_pani",
  },
  {
    nepali: "नदि खोला",
    english: "river water",
    value: "river_water",
  },
  {
    nepali: "आकासे पानी",
    english: "rain water",
    value: "rain_water",
  },
  {
    nepali: "जारको पानी",
    english: "Jar",
    value: "jar",
  },
  {
    nepali: "मुलको पानी",
    english: "natural water",
    value: "natural_water",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const drinkingwaterplaceLists = [
  {
    nepali: "घर आँगनमा",
    english: "home courtyard",
    value: "home_courtyard",
  },
  {
    nepali: "१० मिनेट सम्मको पैदला",
    english: "",
    value: "10_min_walk",
  },
  {
    nepali: "आधा घण्टा सम्मको दुरी",
    english: "half hour walk",
    value: "half_hour_walk",
  },
  {
    nepali: "१ घण्टा वा सो भन्दा माथि",
    english: "1 hour walk",
    value: "1_hour_walk",
  },
];
export const cookingresourceLists = [
  {
    nepali: "दाउरा/काठ(साधरण चुलो )",
    english: "wood",
    value: "wood",
  },
  {
    nepali: "दाउरा/काठ(सुधारिएको चुलो )",
    english: "sudhariyako chulo",
    value: "sudharieko_chu",
  },
  {
    nepali: "मट्टीतेल/स्टोभ",
    english: "kerosene",
    value: "kerosene",
  },
  {
    nepali: "एल.पी.जी.ग्यास",
    english: "LPG Gas",
    value: "lpg",
  },
  {
    nepali: "वायो ग्यास",
    english: "Bio gas",
    value: "bio",
  },
  {
    nepali: "विधुत",
    english: "electricity",
    value: "electric",
  },
  {
    nepali: "सौर्य उर्जा",
    english: "solar energy",
    value: "solar",
  },
  {
    nepali: "गुइठा",
    english: " dung",
    value: "dung",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const lightresourceLists = [
  {
    nepali: "बिधुत लाईन",
    english: "electricity",
    value: "electric",
  },
  {
    nepali: "मट्टीतेल/टुकी",
    english: "kerosene",
    value: "kerosene",
  },
  {
    nepali: "सोलार",
    english: "solar",
    value: "solar",
  },
  {
    nepali: "वायो ग्यास",
    english: "bio gas",
    value: "bio_gas",
  },
  {
    nepali: "टर्च लाईट /ब्याट्री",
    english: "torch battery",
    value: "torch_battery",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const toiletresourceLists = [
  {
    nepali: "शौचालय नभएको",
    english: "Nn toilet",
    value: "no_toilet",
  },
  {
    nepali: "फ्लस भएको(सार्वजनिक ढल)",
    english: "public tank",
    value: "public_tank",
  },
  {
    nepali: "फ्लस भएको(सेफ्टी ट्याङ्क)",
    english: "private tank",
    value: "private_tank",
  },
  {
    nepali: "साधारण खाडलमा ढाकेको",
    english: "ordinary tank",
    value: "ordinary_tank",
  },
  {
    nepali: "सामुदायिक चर्पी",
    english: "shared toilet",
    value: "shared_tolilet",
  },
  {
    nepali: "वाटर सिल",
    english: "water seal",
    value: "water_seal",
  },
  {
    nepali: "शौचालय भएको तर प्रयोग नगरेको",
    english: "toilet not in use",
    value: "toilet_not_use",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const roadLists = [
  {
    nepali: "पक्कि/कालोपत्रो सडक",
    english: "black pitched road",
    value: "black_pitched",
  },
  {
    nepali: "ग्रावेल सडक",
    english: "gravel road",
    value: "gravel",
  },
  {
    nepali: "धुले सडक (कच्ची)",
    english: "dusty road",
    value: "dusty",
  },
  {
    nepali: "गोरेटो बाटो जोडिएको",
    english: "goreto bato",
    value: "goreto",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const wastemanagementLists = [
  {
    nepali: "आफै व्यवस्थापन गर्ने",
    english: "self managed",
    value: "self_managed",
  },
  {
    nepali: "आफै खाडलमा हाल्ने",
    english: "self pit",
    value: "self_pit",
  },
  {
    nepali: "संकलित फोहोर जलाउने",
    english: "burn",
    value: "burn",
  },
  {
    nepali: "बाहिर खाडलमा हाल्ने",
    english: "other pit",
    value: "others_pit",
  },
  {
    nepali: "मल खाडलमा",
    english: "outside dispose",
    value: "outside_dispos",
  },
  {
    nepali: "गोबर ग्यास प्लान्ट प्रयोग गर्ने",
    english: "Gobar gas",
    value: "gobar_gas",
  },
  {
    nepali: "निजि संस्थाले घरबाटै उठाउने",
    english: "private organisation",
    value: "private_org",
  },
  {
    nepali: "अव्यवस्थित तबरले बाहिर जताभावी फ्याल्ने",
    english: "unmanaged",
    value: "unmanaged",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const salttypeLists = [
  {
    nepali: "आयोडिन  युक्त नून",
    english: "Iodine Salt",
    value: "iodine_salt",
  },
  {
    nepali: "ढिको नून",
    english: "dhika salt",
    value: "dhiko_salt",
  },
  {
    nepali: "खुल्ला नून",
    english: "open salt",
    value: "open_salt",
  },
];

export const bankAccount = [
  {
    nepali: "छ",
    english: "Yes",
    value: "account_yes",
  },
  {
    nepali: "छैन",
    english: "No",
    value: "account_no",
  },
];

export const illTreament = [
  {
    nepali: "धामी झाक्री",
    english: "dhami jhakri",
    value: "dhaami_jhakri",
  },
  {
    nepali: "औषधि पसल",
    english: "pharmecy",
    value: "medical",
  },
  {
    nepali: "क्लिनिक",
    english: "clinic",
    value: "clinic",
  },
  {
    nepali: "स्वास्थ्य चौकी",
    english: "health post",
    value: "health_post",
  },
  {
    nepali: "प्राथमिक स्वस्थ केन्द्र",
    english: "primary health center",
    value: "primary_health",
  },
  {
    nepali: "जिल्लाका निजि अस्पताल",
    english: "district private hospital",
    value: "private_hospit",
  },
  {
    nepali: "जिल्ला बाहिर सरकारी अस्पताल",
    english: "public hospital outside the valley",
    value: "public_hospita",
  },
  {
    nepali: "स्थानीय स्वास्थ्य कर्मी",
    english: "health worker",
    value: "healthworker",
  },
];
export const bhattaLists = [
  {
    nepali: "ज्येष्ठ नागरिक",
    english: "old citizen",
    value: "old_citizen",
  },
  {
    nepali: "एकल महिला",
    english: "single women",
    value: "single_women",
  },
  {
    nepali: "पूर्ण अपाङ्ग",
    english: "full disable",
    value: "full_disable",
  },
  {
    nepali: "आंसिक अपाङ्ग",
    english: "half disable",
    value: "half_disable",
  },
  {
    nepali: "बाल सुरक्षा अनुदान",
    english: "child security",
    value: "child_security",
  },
  {
    nepali: "दलित वर्ग",
    english: "Dalit",
    value: "dalit",
  },
];
export const businessLists = [
  {
    nepali: "करुवा",
    english: "karuwa",
    value: "karuwa",
  },
  {
    nepali: "खोलामा जालबाट माछा मार्ने तथा बिक्रि वितरण गर्ने",
    english: "fishing",
    value: "fishing",
  },
  {
    nepali: "हातेकागज",
    english: "Hand paper",
    value: "hand_paper",
  },
  {
    nepali: "अल्लो",
    english: "allo",
    value: "allo",
  },
  {
    nepali: "खुकुरी",
    english: "khukuri",
    value: "khukuri",
  },
  {
    nepali: "राडीपाखी",
    english: "raadi pakhi",
    value: "raadi_pakhi",
  },
  {
    nepali: "कुराउनी",
    english: "kurauni",
    value: "kurauni",
  },
  {
    nepali: "सक्खर",
    english: "sakkhar",
    value: "sakkhar",
  },
  {
    nepali: "छुर्पी",
    english: "churpi",
    value: "churpi",
  },
  {
    nepali: "घिउ",
    english: "ghue",
    value: "ghiu",
  },
  {
    nepali: "फर्निचर",
    english: "furniture",
    value: "furniture",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
// export const herbLists = [
// 	{
// 		nepali: "अदामचल",
// 		english: "adaamchal",
// 		value: "adaamchal"
// 	},
// 	{
// 		nepali: "आखनबेद",
// 		english: "aakhanbed",
// 		value: "ankhanbed"
// 	},
// 	{
// 		nepali: "कुट्की",
// 		english: "kutki",
// 		value: "kutki"
// 	},
// 	{
// 		nepali: "ठुलो ओखती",
// 		english: "thulo okhati",
// 		value: "thulo_okhati"
// 	},
// 	{
// 		nepali: "हर्रो",
// 		english: "harro",
// 		value: "harro"
// 	},
// 	{
// 		nepali: "अमला",
// 		english: "amala",
// 		value: "aamla"
// 	},
// 	{
// 		nepali: "टिमुर",
// 		english: "timur",
// 		value: "timur"
// 	},
// 	{
// 		nepali: "चिरैतो",
// 		english: "chairato",
// 		value: "chirauto"
// 	},
// 	{
// 		nepali: "चानो",
// 		english: "chano",
// 		value: "chano"
// 	},
// 	{
// 		nepali: "बर्रो",
// 		english: "barro",
// 		value: "barro"
// 	},
// 	{
// 		nepali: "बोझो",
// 		english: "bojho",
// 		value: "bojho"
// 	},
// 	{
// 		nepali: "अन्य",
// 		english: "other",
// 		value: "other"
// 	}
// ];
export const landUsedliving = [
  {
    nepali: "निजि/नम्बरी",
    english: "private/self",
    value: "self",
  },
  {
    nepali: "गुठि",
    english: "guthi",
    value: "guthi",
  },
  {
    nepali: "सार्वजनिक",
    english: "public",
    value: "public_land",
  },
  {
    nepali: "ऐलानी",
    english: "yelani",
    value: "yelani",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];

export const housetype = [
  {
    nepali: "आरसीसी",
    english: "RCC",
    value: "rcc",
  },
  {
    nepali: "ढुंगाको घर (सिमेन्टको जोडाइ)",
    english: "Stone cement",
    value: "stone_cement",
  },
  {
    nepali: "पक्कि इट्टाको घर (सिमेन्टको जोडाइ)",
    english: "brick cement",
    value: "brick_cement",
  },
  {
    nepali: "ढुंगाको घर (माटोको जोड़ाई)",
    english: "stone mud",
    value: "stone_mud",
  },
  {
    nepali: "काचो इट्टाको घर (माटोको जोड़ाई)",
    english: "vrick mud",
    value: "brick_mud",
  },
  {
    nepali: "बास/टाटीको घर",
    english: "bamboo",
    value: "bamboo",
  },
  {
    nepali: "जस्ता पाताले बेरेको",
    english: "jasta pata",
    value: "jasta_pata",
  },
  {
    nepali: "काठको खम्बा (काठको घर )",
    english: "wooden",
    value: "wooden",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const rooftype = [
  {
    nepali: "जस्ता पाता",
    english: "jasta pata",
    value: "steel",
  },
  {
    nepali: "फुस वा खरको",
    english: "fush/ khar",
    value: "roof_grass",
  },
  {
    nepali: "टायल/खपडा/ढुंगा",
    english: "tiles / khapada/stone",
    value: "tiles",
  },
  {
    nepali: "सिमेन्ट/ ढलान",
    english: "RCC",
    value: "rcc",
  },
  {
    nepali: "काठ/ फल्याक",
    english: "Wooden",
    value: "wooden",
  },
  {
    nepali: "माटो",
    english: "mud",
    value: "mud",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const hasLanddocument = [
  {
    nepali: "छ",
    english: "yes",
    value: "land_doc_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "land_doc_no",
  },
];
export const doHouseCriteriafullfill = [
  {
    nepali: "छ",
    english: "yes",
    value: "criteria_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "criteria_no",
  },
  {
    nepali: "थाहा छैन",
    english: "donot know",
    value: "donot_know_cri",
  },
];
export const hasHouseMappass = [
  {
    nepali: "छ",
    english: "yes",
    value: "map_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "map_know",
  },
];
export const skillDetail = [
  {
    nepali: "निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर )",
    english: "onstruction",
    value: "construction",
  },
  {
    nepali: "कृषि सम्बन्धी (जे.टि, जे.टि.ए र खद प्रसोधन )",
    english: "Agriculture",
    value: "agriculture",
  },
  {
    nepali: "बास तथा छालाबाट निर्मित हस्त सामग्री",
    english: "Hand made",
    value: "hand_made",
  },
  {
    nepali:
      "सूचना प्रविधि ,इलेक्ट्रोनिकस, इलेक्ट्रोनिकल( कम्पुटर /मोबाइल/रेडियो/घडी मर्मत)",
    english: "Electronical,Electronic",
    value: "electric",
  },
  {
    nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार",
    english: "Fashion",
    value: "fashion",
  },
  {
    nepali: "जनस्वास्थ सम्बन्धी",
    english: "Public Health",
    value: "health",
  },
  {
    nepali: "पशु स्वास्थ्य सम्बन्धी",
    english: "Animal Health",
    value: "animal_health",
  },
  {
    nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धी",
    english: "Tourist, tour guide travel and respect ",
    value: "tourism",
  },
  {
    nepali: "कला सम्बन्धी",
    english: "Art related",
    value: "art",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const birthCertificate = [
  {
    nepali: "छ",
    english: "yes",
    value: "birth_cert_yes",
  },
  { nepali: "छैन", english: "no", value: "birth_cert_no" },
];
export const childvaccine = [
  {
    nepali: "छ",
    english: "yes",
    value: "vaccaine_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "vaccaine_no",
  },
];
export const familyPlanning = [
  {
    nepali: "छ",
    english: "yes",
    value: "yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "no",
  },
];
export const deliveryFromdoctor = [
  {
    nepali: "छ",
    english: "yes",
    value: "delivery_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "delivery_no",
  },
];
export const pregnantnutrition = [
  {
    nepali: "छ",
    english: "yes",
    value: "nutrition_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "nutrition_no",
  },
];
export const pregnanctcheck = [
  {
    nepali: "छ",
    english: "yes",
    value: "check_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "check_no",
  },
];
export const childnutrition = [
  {
    nepali: "छ",
    english: "yes",
    value: "mal_nutri_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "mal_nutri_no",
  },
];
export const childlabour = [
  {
    nepali: "छ",
    english: "yes",
    value: "yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "no",
  },
];
export const earlymarriage = [
  {
    nepali: "छ",
    english: "yes",
    value: "e_marry_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "e_marry_no",
  },
];
export const pregnanttetanus = [
  {
    nepali: "छ",
    english: "yes",
    value: "tetanus_yes",
  },
  {
    nepali: "छैन",
    english: "no",
    value: "tetanus_no",
  },
];
export const preprimary = [
  {
    nepali: "१",
    english: "1",
    value: "class_1",
  },
  {
    nepali: "२",
    english: "2",
    value: "class_2",
  },
  {
    nepali: "३",
    english: "3",
    value: "class_3",
  },
  {
    nepali: "४",
    english: "4",
    value: "class_4",
  },
  {
    nepali: "५",
    english: "5",
    value: "class_5",
  },
];

export const answererRelation = [
  {
    nepali: "घरमुली आफै",
    english: "Self house owner",
    value: "self_house_own",
  },
  {
    nepali: "श्रीमान",
    english: "Husband",
    value: "husband",
  },
  {
    nepali: "श्रीमती",
    english: "Wife",
    value: "wife",
  },
  {
    nepali: "आमा",
    english: "Mother",
    value: "mother",
  },
  {
    nepali: "बुवा",
    english: "Father",
    value: "father",
  },
  {
    nepali: "छोरा",
    english: "Son",
    value: "son",
  },
  {
    nepali: "छोरी",
    english: "Daughter",
    value: "daughter",
  },
  {
    nepali: "बुहारी",
    english: "Buhari",
    value: "buhari",
  },
  {
    nepali: "ज्वाई",
    english: "Jwai",
    value: "jwai",
  },
  {
    nepali: "सासु",
    english: "Sasu",
    value: "sashu",
  },
  {
    nepali: "ससुरा",
    english: "Sasura",
    value: "sasura",
  },
  {
    nepali: "काका",
    english: "Kaka",
    value: "kaka",
  },
  {
    nepali: "काकी",
    english: "Kaki",
    value: "kaki",
  },
  {
    nepali: "फुपू",
    english: "Fupu",
    value: "fupu",
  },
  {
    nepali: "फुपाजु",
    english: "Fupaju",
    value: "fupaju",
  },
  {
    nepali: "मामा",
    english: "Mama",
    value: "mama",
  },
  {
    nepali: "माइजु",
    english: "Maiju",
    value: "maiju",
  },
  {
    nepali: "नाती",
    english: "Nati",
    value: "nati",
  },
  {
    nepali: "नातिनी",
    english: "Natini",
    value: "natini",
  },
  {
    nepali: "दाजु",
    english: "Daju",
    value: "daju",
  },
  {
    nepali: "भाई",
    english: "Bhai",
    value: "bhai",
  },
  {
    nepali: "दिदि",
    english: "Didi",
    value: "didi",
  },
  {
    nepali: "बहिनी",
    english: "",
    value: "bahini",
  },
  {
    nepali: "भाउजु",
    english: "Bhauju",
    value: "bhauju",
  },
  {
    nepali: "भाई बुहारी",
    english: "Bhai Buhari",
    value: "bhai_buhari",
  },
  {
    nepali: "जेठान",
    english: "Jethan",
    value: "jethan",
  },
  {
    nepali: "ठुलो बुवा",
    english: "Thulo Buwa",
    value: "thulo_bbuwa",
  },
  {
    nepali: "ठुलो आमा",
    english: "Thulo Aama",
    value: "thulo_aama",
  },
  {
    nepali: "हजुर बुवा",
    english: "Hajur Buwa",
    value: "grand_father",
  },
  {
    nepali: "हजुर आमा",
    english: "Hajur Aama",
    value: "grand_mother",
  },
];
export const agriCropTypeList = [
  {
    nepali: "धान",
    english: "Paddy",
    value: "paddy",
  },
  {
    nepali: "जौ",
    english: "Jau",
    value: "jau",
  },
  {
    nepali: "मकै",
    english: "Maize",
    value: "maize",
  },
  {
    nepali: "कोदो",
    english: "Kodo",
    value: "kodo",
  },
  {
    nepali: "फापर",
    english: "Fapar",
    value: "fapar",
  },
  {
    nepali: "गहू",
    english: "Gahu",
    value: "ghau",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const agriDaalType = [
  {
    nepali: "कालो दाल",
    english: "Black Daal",
    value: "black_daal",
  },
  {
    nepali: "रहर",
    english: "Rahar",
    value: "rahar",
  },
  {
    nepali: "मुसुरी",
    english: "Musuri",
    value: "musuri",
  },
  {
    nepali: "चना",
    english: "Chana",
    value: "chana",
  },
  {
    nepali: "भट्मास",
    english: "Bhatmas",
    value: "bhatmas",
  },
  {
    nepali: "गहत",
    english: "Gahat",
    value: "gahat",
  },
  {
    nepali: "मस्याम",
    english: "Mashyang",
    value: "mashyam",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];
export const agriOilType = [
  {
    nepali: "तोरी",
    english: "Tori",
    value: "tori",
  },
  {
    nepali: "सर्स्यु",
    english: "Sarshu",
    value: "sarshu",
  },
  {
    nepali: "आलस",
    english: "Aalash",
    value: "aalash",
  },
  {
    nepali: "फिलुंगे",
    english: "Filunge",
    value: "filunge",
  },
  {
    nepali: "तिल",
    english: "Teel",
    value: "teel",
  },
  {
    nepali: "सूर्यमुखी",
    english: "Sunflower",
    value: "sunflower",
  },
  {
    nepali: "अन्य",
    english: "other",
    value: "other",
  },
];
export const agriVegetableType = [
  {
    nepali: "आलु",
    english: "Potato",
    value: "potato",
  },
  {
    nepali: "बन्दा",
    english: "Cabbage",
    value: "cabbage",
  },
  {
    nepali: "काउली",
    english: "Cauliflower",
    value: "cauliflower",
  },
  {
    nepali: "बोडी",
    english: "Bodi",
    value: "bodi",
  },
  {
    nepali: "सिमि",
    english: "Simi",
    value: "simi",
  },
  {
    nepali: "गोलभेडा",
    english: "Tomato",
    value: "tomato",
  },
  {
    nepali: "काक्रो",
    english: "Cucumber",
    value: "cucumber",
  },
  {
    nepali: "लौका",
    english: "Lauka",
    value: "lauka",
  },
  {
    nepali: "फर्सी",
    english: "Pumpkin",
    value: "pamkin",
  },
  {
    nepali: "पर्वल",
    english: "Parwal",
    value: "parwal",
  },
  {
    nepali: "करेला",
    english: "Bittergourd",
    value: "bittergroud",
  },
  {
    nepali: "घिरौला",
    english: "Ghiraula",
    value: "ghiraula",
  },
  {
    nepali: "चिचिन्डो",
    english: "Chichindo",
    value: "chichindo",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const agriFruitType = [
  {
    nepali: "आँप",
    english: "",
    value: "mango",
  },
  {
    nepali: "लिची",
    english: "litchi",
    value: "litchi",
  },
  {
    nepali: "केरा",
    english: "Banana",
    value: "banana",
  },
  {
    nepali: "सुन्तला",
    english: "Orange",
    value: "orange",
  },
  {
    nepali: "जुनार",
    english: "Junar",
    value: "junar",
  },
  {
    nepali: "कागती",
    english: "Lemon",
    value: "lemon",
  },
  {
    nepali: "स्याउ",
    english: "Apple",
    value: "apple",
  },
  {
    nepali: "किवी",
    english: "Kiwi",
    value: "kiwi",
  },
  {
    nepali: "नासपाती",
    english: "Pear",
    value: "naspati",
  },
  {
    nepali: "अभोगाड़ो",
    english: "Avogado",
    value: "avogado",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const cashCropType = [
  {
    nepali: "धसिंगरे",
    english: "Dhasingare",
    value: "dhasingare",
  },
  {
    nepali: "अलैची",
    english: "Alaichi",
    value: "alaichi",
  },
  {
    nepali: "रुद्राक्ष(दाना)",
    english: "Rudrakshya",
    value: "rudraksh",
  },
  {
    nepali: "अम्रिसो",
    english: "Amriso",
    value: "amriso",
  },
  {
    nepali: "कफी",
    english: "Coffee",
    value: "coffee",
  },
  {
    nepali: "चिया",
    english: "Tea",
    value: "tea",
  },
  {
    nepali: "निबुवा",
    english: "Nibuwa",
    value: "nibuwa",
  },
  {
    nepali: "अदुवा",
    english: "Ginger",
    value: "ginger",
  },
  {
    nepali: "बेसार",
    english: "Beshar",
    value: "besaar",
  },
  {
    nepali: "लसुन",
    english: "Garlic",
    value: "garlic",
  },
  {
    nepali: "प्याज",
    english: "Onion",
    value: "onion",
  },
  {
    nepali: "च्याउ",
    english: "Mushroom ",
    value: "mushroom",
  },
  {
    nepali: "उखु",
    english: "Sugarcane",
    value: "sugarcane",
  },
  {
    nepali: "बदाम",
    english: "Nut",
    value: "nut",
  },
  {
    nepali: "खुर्सानी",
    english: "Chilly",
    value: "chilly",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];
export const autoSuggetionString = [
  { nepali: "वडा", english: "Ward", value: "wardNumber" },
  { nepali: "लिङ्ग", english: "Gender", value: "gender" },

  { nepali: "उमेर", english: "Age", value: "age" },
  { nepali: "वार्षिक आम्दानी", english: "Annual Income", value: "totalIncome" },
  {
    nepali: "वार्षिक खर्च",
    english: "Annual Expenditure",
    value: "totalExpenditure",
  },
  {
    nepali: "घरमुली संगको नाता",
    english: "AnswererRelation",
    value: "answererRelation",
  },
  { nepali: "जात/जाती", english: "Ethnics", value: "ethnics" },
  { nepali: "कुन जनजाती", english: "Which Ethnics", value: "caste" },
  { nepali: "धर्म", english: "Religion", value: "religion" },
  { nepali: "शिक्षा", english: "Education", value: "educationLevel" },
  {
    nepali: "पूर्व प्राथमिक",
    english: "Preprimary",
    value: "prePrimaryDetails",
  },
  { nepali: "आधारभूत तह", english: "Primary", value: "primaryDetails" },
  { nepali: "माध्यमिक तह", english: "Secondary", value: "secondaryDetails" },
  { nepali: "पेशा", english: "Occupation", value: "levelOneJob" },
  { nepali: "नोकरी  जागिर", english: "Job", value: "levelTwoJob" },
  {
    nepali: "शिक्षक/सुरक्षा निकाय",
    english: "Teacher/Security",
    value: "levelThreeJob",
  },
  {
    nepali: "खेतिवाली को किसिम",
    english: "Agriculture Type",
    value: "agricultureDetail_agricultureProductionSalesType",
  },
  {
    nepali: "अन्न वाली को किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_crops",
  },
  {
    nepali: "दालको किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_daal",
  },
  {
    nepali: "तेलहनको किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_oilseed",
  },
  {
    nepali: "तरकारीको किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_vegetable",
  },
  {
    nepali: "फलफुलको किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_fruit",
  },
  {
    nepali: "नगदेवालीको किसिम",
    english: "Agriculture Type",
    value: "agricultureProductionSalesType_cash_crops",
  },

  {
    nepali: "स्वास्थ्य",
    english: "Health Condition",
    value: "healthCondition",
  },
  {
    nepali: "दिर्घ रोग",
    english: "Unhealthy Disease",
    value: "unhealthyDisease",
  },
  { nepali: "अपांगताको किसिम", english: "Disable", value: "type" },
  {
    nepali: "अपांगताको स्थिति",
    english: "Disable Condition",
    value: "condition",
  },
  { nepali: "अपांगताको कार्ड", english: "Disable Card", value: "hasCard" },
  { nepali: "कार्डको किसिम", english: "", value: "cardType" },
  { nepali: "मातृभाषा", english: "MotherTongue", value: "motherTongue" },
  { nepali: "रगत समुह", english: "Blood Group", value: "bloodGroup" },
  {
    nepali: "बैवाहिक स्थिति",
    english: "MartialStatus",
    value: "martialStatus",
  },
  { nepali: "बसोबासको अवस्था", english: "Living Type", value: "livingType" },
  {
    nepali: "इस्थाइ बसोबास",
    english: "Permanent Living Type",
    value: "permanentLivingAddress",
  },
  {
    nepali: "खानेपानीको श्रोत",
    english: "Drinking Water Resource",
    value: "waterSource",
  },
  {
    nepali: "खानेपानीको उपलब्ध स्थान",
    english: "Drinking Water Place",
    value: "distanceToWaterSource",
  },
  {
    nepali: "खाना पकाउने प्रमुख इन्धन/चुलो",
    english: "Cooking Resource",
    value: "cookingSource",
  },
  {
    nepali: "वत्तिको मुख्य स्रोत",
    english: "Light Resource",
    value: "electricSource",
  },
  { nepali: "शौचालयको किसिम", english: "Toilet Type", value: "toilet" },
  { nepali: "सडक/बाटोको अवस्था", english: "Road Type", value: "road" },
  {
    nepali: "फोहर मैलाको व्यवस्थापन",
    english: "Waste Management",
    value: "wasteDisposal",
  },
  { nepali: "नून प्रयोग", english: "Salt Type", value: "saltUsed" },
  { nepali: "घरको सुबिधा", english: "House Facility", value: "item_title" },
  { nepali: "पशुपंक्षी", english: "live Stock", value: "detail_name" },

  {
    nepali: "घरेलु व्यवसाय",
    english: "Home Business",
    value: "home_homeBusinessName",
  },
  // {
  // 	nepali: "घरेलु जडिबुटी व्यवसाय",
  // 	english: "Home Herb Business",
  // 	value: "herb_herbBusinessName"
  // },

  {
    nepali: "बैंक तथा वित्तीय संस्थामा खाता",
    english: "Bank Account",
    value: "bankAccount",
  },
  { nepali: "बिरामी जाँच ", english: "illTreament", value: "illTreament" },
  { nepali: "भत्ता को किसिम", english: "Bhatta list", value: "welfare_title" },
  {
    nepali: "जग्गाको किसिम",
    english: "Land Used Living",
    value: "landUsedLiving",
  },
  { nepali: "घरको किसिम", english: "House Type", value: "houseType" },
  { nepali: "घरको छानाको किसिम", english: "Roof Type", value: "roofType" },
  {
    nepali: "जग्गाको कागजपत्र",
    english: "Has Land Document",
    value: "hasLandDocument",
  },
  {
    nepali: "घर मापदण्ड",
    english: "House Criteria",
    value: "doHouseCriteriaFullfill",
  },
  {
    nepali: "घरको नक्सा पास",
    english: "House Map Pass",
    value: "hasHouseMapPass",
  },
  { nepali: "शिप र तालिम", english: "Skill Detail", value: "detail" },
  {
    nepali: "जन्म दर्ता",
    english: "Birth Certificate Detail",
    value: "birthCertificate",
  },
  {
    nepali: "बालबालिकालाई खोप",
    english: "Child Vaccination",
    value: "childVaccine",
  },
  {
    nepali: "परिवार नियोजनलाई साधन प्रयोग",
    english: "Family Planing",
    value: "familyPlanning",
  },
  {
    nepali: "स्वास्थ्य कर्मीबाट सुत्केरी",
    english: "Delivery From Doctor",
    value: "deliveryFromDoctor",
  },
  {
    nepali: "गर्भवतीलाई पोषण युक्त खाना",
    english: "Nutrition for Pregnant",
    value: "pregnantNutrition",
  },
  {
    nepali: "गर्भवती महिलाको जाँच ",
    english: "Check-Up of Pregnant",
    value: "pregnanctCheck",
  },
  {
    nepali: "पोषण सम्बन्धी समस्या",
    english: "Child Nutrition",
    value: "childNutrition",
  },
  { nepali: "वालवालिका श्रम", english: "Child Labour", value: "childLabour" },
  { nepali: "वाल विवाह", english: "Early Marriage", value: "earlyMarriage" },
  {
    nepali: "टिटानस खोप",
    english: "Pregnant Tetanus",
    value: "pregnantTetanus",
  },
  {
    nepali: "विदेसिएको देश",
    english: "Abroad Country",
    value: "foreignCountry",
  },
  {
    nepali: "विदेसिएको कारण",
    english: "Abroad Reason",
    value: "abroadReason",
  },
];

export const abroadCountryList = [
  {
    nepali: "भारत",
    english: "India",
    value: "india",
  },
  {
    nepali: "कतार",
    english: "Quatar",
    value: "quatar",
  },
  {
    nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
    english: "SAARC",
    value: "saarc",
  },
  {
    nepali: "साउदी अरब",
    english: "Saudi Arab",
    value: "saudi_arab",
  },
  {
    nepali: "मलेसिया",
    english: "Malayasia",
    value: "malaysia",
  },
  {
    nepali: "जापान",
    english: "Japan",
    value: "japan",
  },
  {
    nepali: "यु.ए.ई. (दुबई)",
    english: "UAE",
    value: "uae",
  },
  {
    nepali: "दक्षिण कोरिया",
    english: "South Korea",
    value: "south_korea",
  },
  {
    nepali: "अष्ट्रेलिया",
    english: "Australia",
    value: "australia",
  },
  {
    nepali: "अमेरिका",
    english: "USA",
    value: "usa",
  },
  {
    nepali: "इराक",
    english: "Iraq",
    value: "iraq",
  },
  {
    nepali: "बेलायत",
    english: "England",
    value: "england",
  },
  {
    nepali: "क्यानाडा",
    english: "Canada",
    value: "canada",
  },
  {
    nepali: "जर्मनी",
    english: "Germany",
    value: "germany",
  },
];
export const abroadReasonList = [
  {
    nepali: "शिक्षा",
    english: "Education",
    value: "education",
  },
  {
    nepali: "रोजगार",
    english: "Employment",
    value: "employment",
  },
  {
    nepali: "घरेलु कामदार (महिला)",
    english: "House worker",
    value: "house_worker",
  },
  {
    nepali: "डी.भी. चिठ्ठा",
    english: "DV Lottery",
    value: "dv_lottery",
  },
];

export const livestockDiffType = [
  {
    nepali: "गाई",
    english: "Cow",
    value: "cow",
  },
  {
    nepali: "भैसी",
    english: "Buffalo",
    value: "buffallo",
  },
  {
    nepali: "खसी/बोका",
    english: "Goat",
    value: "goat/boka",
  },
  {
    nepali: "बाख्रा",
    english: "Baakhra",
    value: "baakhra",
  },
  {
    nepali: "राँगा/पाडा/पाडी",
    english: "Ranga",
    value: "ranga",
  },
  {
    nepali: "सुँगुर/बंगुर",
    english: "Pig",
    value: "pig",
  },
  {
    nepali: "भेडा",
    english: "Sheep",
    value: "sheep",
  },
  {
    nepali: "बदेल",
    english: "Badel",
    value: "bandel",
  },
  {
    nepali: "गोरु",
    english: "OX",
    value: "garu",
  },
  {
    nepali: "गधा",
    english: "Ass",
    value: "gadha",
  },
  {
    nepali: "घोडा",
    english: "Horse",
    value: "horse",
  },
  {
    nepali: "याक",
    english: "Yak",
    value: "yak",
  },
  {
    nepali: "च्यांग्रा",
    english: "Chyangra",
    value: "chyangra",
  },
  {
    nepali: "कुखुरा",
    english: "Hen",
    value: "chicken",
  },
  {
    nepali: "हाँस",
    english: "Duck",
    value: "duck",
  },
  {
    nepali: "अस्ट्रिच",
    english: "Ostrich",
    value: "austrich",
  },
  {
    nepali: "परेवा",
    english: "Pegion",
    value: "pegion",
  },
  {
    nepali: "अन्य",
    english: "Other",
    value: "other",
  },
];

export const livestockType = [
  {
    nepali: "पशु",
    english: "Animal",
    value: "animal",
  },
  {
    nepali: "पन्छी",
    english: "Bird",
    value: "bird",
  },
];

export const searchOption = {
  gender: [
    {
      nepali: "पुरुष",
      english: "Male",
      value: "male",
    },
    {
      nepali: "महिला",
      english: "Female",
      value: "female",
    },
    {
      nepali: "तेश्रो लिंगी",
      english: "Third Gender",
      value: "third_gender",
    },
  ],
  wardNumber: [...Array(totalWard).keys()].map((each) => ({
    nepali: `वडा न ${getNepaliNumber(each + 1)}`,
    english: `Ward Number ${each + 1}`,
    value: `${each + 1}`,
  })),
  agricultureDetail_agricultureProductionSalesType: [
    { nepali: "अन्न वाली", english: "", value: "crops" },
    { nepali: "दाल वाली", english: "", value: "daal" },
    { nepali: "तेलहन उत्पादन", english: "", value: "oilseed" },
    { nepali: "तरकारी उत्पादन", english: "", value: "vegetable" },
    { nepali: "फलफुल", english: "", value: "fruit" },
    { nepali: "नगदेवाली", english: "", value: "cash_crops" },
    { nepali: "फुलखेती", english: "", value: "flower" },
  ],

  // detail_name: [
  // 	{ nepali: "पशुि", english: "Animal", value: "animal" },
  // 	{ nepali: "पन्छी", english: "Bird", value: "bird" },
  //
  // ],
  // agricultureProductionSalesType_crops: [
  // 	{ nepali: "अन्न वालि", english: "", value: "crops" },
  // 	{ nepali: "दाल वालि", english: "", value: "daal" },
  // 	{ nepali: "तेलहन उत्पादन", english: "", value: "oilseed" },
  // 	{ nepali: "तरकारी उत्पादन", english: "", value: "vegetable" },
  // 	{ nepali: "फलफुल", english: "", value: "fruit" },
  // 	{ nepali: "नगदेवाली", english: "", value: "cash_crops" },
  // 	{ nepali: "फुलखेती", english: "", value: "flower" }
  // ],
  item_title: [
    {
      nepali: "टि.भी",
      english: "T.V",
      value: "TV",
    },
    {
      nepali: "रेडियो",
      english: "Radio",
      value: "Radio",
    },
    {
      nepali: "टेलिफोन",
      english: "Telephone",
      value: "Telephone",
    },
    {
      nepali: "मोबाइल",
      english: "Mobile",
      value: "Mobile",
    },
    {
      nepali: "ल्यापटप",
      english: "Laptop",
      value: "Laptop",
    },
    {
      nepali: "कम्प्युटर",
      english: "Computer",
      value: "Computer",
    },
    {
      nepali: "मोटरसाइकल",
      english: "Motor Cycle",
      value: "Motorcycle",
    },
    {
      nepali: "स्कुटर",
      english: "Scooter",
      value: "Scooter",
    },
    {
      nepali: "कार",
      english: "Car",
      value: "Car",
    },
    {
      nepali: "जिप",
      english: "Jeep",
      value: "Jeep",
    },
    {
      nepali: "ट्राकटर",
      english: "Tractor",
      value: "Tractor",
    },
    {
      nepali: "ट्रक",
      english: "Truck",
      value: "Truck",
    },
    {
      nepali: "अटो",
      english: "Auto",
      value: "Auto",
    },
    {
      nepali: "टेम्पो",
      english: "Tempo",
      value: "Tempo",
    },
    {
      nepali: "डोज़र",
      english: "Dozar",
      value: "Dozar",
    },
    {
      nepali: "टिप्पर",
      english: "Tipper",
      value: "Tipper",
    },
    {
      nepali: "स्काभेटर",
      english: "Skavater",
      value: "Skavater",
    },
  ],

  answererRelation: answererRelation,
  ethnics: casteList,
  caste: [
    {
      nepali: "राई",
      english: "Rai",
      value: "rai",
    },
    {
      nepali: "मगर",
      english: "Magar",
      value: "magar",
    },
    {
      nepali: "शेर्पा",
      english: "Sherpa",
      value: "sherpa",
    },
    {
      nepali: "गुरुङ",
      english: "Gurung",
      value: "gurung",
    },
    {
      nepali: "तामाङ्ग",
      english: "Tamang",
      value: "tamang",
    },
    {
      nepali: "नेवार",
      english: "Newar",
      value: "newar",
    },
    {
      nepali: "थकाली",
      english: "Thakali",
      value: "thakali",
    },
    {
      nepali: "लिम्बु",
      english: "Limbu",
      value: "limbu",
    },
    {
      nepali: "भुजेल",
      english: "Bhujel",
      value: "bhujel",
    },
    {
      nepali: "जिरेल",
      english: "Jirel",
      value: "jirel",
    },
    {
      nepali: "गिरि",
      english: "Giri",
      value: "giri",
    },
    {
      nepali: "पुरी",
      english: "Puri",
      value: "puri",
    },
    {
      nepali: "भारती",
      english: "Bharati",
      value: "bharati",
    },
    {
      nepali: "सन्यासी/दशनामी",
      english: "Sansayi",
      value: "sansayi",
    },
    {
      nepali: "योगी",
      english: "Yogi",
      value: "yogi",
    },
    {
      nepali: "कुँवर",
      english: "Kunwar",
      value: "kunwar",
    },
  ],

  religion: religionList,
  educationLevel: educationList,
  prePrimaryDetails: preprimaryList,
  primaryDetails: primaryList,
  secondaryDetails: secondaryList,
  levelOneJob: occupationList,
  levelTwoJob: jobList,
  levelThreeJob: teacherList,
  healthCondition: healthconditionList,
  unhealthyDisease: unhealthy,
  type: mentaltype,
  condition: mentalcondition,
  hasCard: mentalCardYesNo,
  cardType: mentalCardtype,
  motherTongue: mothertongue,
  bloodGroup: bloodgroup,
  martialStatus: marriage,
  livingType: livingTypeList,
  permanentLivingAddress: permanent,
  waterSource: drinkingwaterLists,
  distanceToWaterSource: drinkingwaterplaceLists,
  cookingSource: cookingresourceLists,
  electricSource: lightresourceLists,
  toilet: toiletresourceLists,
  road: roadLists,
  wasteDisposal: wastemanagementLists,
  saltUsed: salttypeLists,
  bankAccount: bankAccount,
  illTreament: illTreament,
  welfare_title: bhattaLists,
  home_homeBusinessName: businessLists,
  // herb_herbBusinessName: herbLists,
  landUsedLiving: landUsedliving,
  houseType: housetype,
  roofType: rooftype,
  hasLandDocument: hasLanddocument,
  doHouseCriteriaFullfill: doHouseCriteriafullfill,
  hasHouseMapPass: hasHouseMappass,
  detail: skillDetail,
  birthCertificate: birthCertificate,
  childVaccine: childvaccine,
  familyPlanning: familyPlanning,
  deliveryFromDoctor: deliveryFromdoctor,
  pregnantNutrition: pregnantnutrition,
  pregnanctCheck: pregnanctcheck,
  childNutrition: childnutrition,
  childLabour: childlabour,
  earlyMarriage: earlymarriage,
  pregnantTetanus: pregnanttetanus,
  agricultureProductionSalesType_crops: agriCropTypeList,
  agricultureProductionSalesType_daal: agriDaalType,
  agricultureProductionSalesType_oilseed: agriOilType,
  agricultureProductionSalesType_vegetable: agriVegetableType,
  agricultureProductionSalesType_fruit: agriFruitType,
  agricultureProductionSalesType_cash_crops: cashCropType,
  detail_name: livestockType,
  foreignCountry: abroadCountryList,
  abroadReason: abroadReasonList,
};
