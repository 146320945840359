import { Grid, Typography } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import Axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Card from "../../common/Card/Card.jsx";
import CardBody from "../../common/Card/CardBody.jsx";
import CardHeader from "../../common/Card/CardHeader";
import GridContainer from "../../common/Grid/GridContainer.jsx";
import GridItem from "../../common/Grid/GridItem.jsx";
import ListHouse from "./ListHouse";
import { CSVLink } from "react-csv";
import { Button } from "antd";
import getNepaliNumber from "../../../utils/getNepaliNumber";
import isEmpty from "../../../utils/validation/is-empty";

class index extends Component {
	state = {
		houses: [],
		loading: true,
		sn: "",
		houseNumber: "",
		wardNumber: "",
		ownerName: "",
		csvData: [],
	};
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value }, () =>
			this.filterData()
		);
	};
	componentDidMount() {
		const ward = parseInt(sessionStorage.getItem("ward") || 1);
		Axios.get(`/api/house/list/${ward}`)
			.then((res) => {
				const csvData = this.getCSVData(res.data);
				this.setState({
					houses: res.data,
					temp: res.data,
					csvData,
					loading: false,
				});
			})
			.catch((err) => {
				console.log("dataSurvey err", err);
			});
	}
	getCSVData = (data) => {
		let tile = [];
		let header = [
			"क्र. स.",
			"सर्बेक्षक कोड",
			"वडा नम्बर",
			"घर नम्बर",
			"घरमुली को नाम",
			"घरमुलीको फोन",
			"साबिक गाबिस",
			"साबिक वडा",
			"टोल नाम",
			"मार्ग नाम",
		];
		tile.push(header);
		data
			.sort((a, b) => a.house.wardNumber - b.house.wardNumber)
			.forEach((each, i) => {
				let row = [
					getNepaliNumber(i + 1),
					each.house.dataCollectionCode || "",
					getNepaliNumber(each.house.wardNumber) || "",
					getNepaliNumber(each.house.houseNumber) || "",
					each.fullName || "",
					getNepaliNumber(each.contactNumber) || "",
					each.house.oldVDC || "",
					getNepaliNumber(each.house.oldWardNumber) || "",
					each.house.toleName || "",
					each.house.streetName || "",
				];

				tile.push(row);
			});
		return tile;
	};

	filterData = () => {
		const { temp, houseNumber, wardNumber, ownerName } = this.state;

		var houseNumberFilter = isEmpty(houseNumber)
			? temp
			: temp
					.filter(({ house }) =>
						house.houseNumber.toString().startsWith(houseNumber)
					)
					.sort((a, b) => a.house.houseNumber - b.house.houseNumber);

		var houseWardNumberFilter = isEmpty(wardNumber)
			? houseNumberFilter
			: houseNumberFilter.filter(({ house }) =>
					house.wardNumber.toString().startsWith(wardNumber)
			  );
		var houseOwnerNameFilter = isEmpty(ownerName)
			? houseWardNumberFilter
			: houseWardNumberFilter.filter((each) =>
					each.fullName.toString().includes(ownerName)
			  );
		const csvData = this.getCSVData(houseOwnerNameFilter);
		this.setState({ houses: houseOwnerNameFilter, csvData });
	};

	render() {
		const { classes } = this.props;
		const {
			houses,
			loading,
			csvData,
			houseNumber,
			ownerName,
			wardNumber,
		} = this.state;
		return (
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary">
							<Grid
								container
								direction="row"
								justify="space-between"
								alignItems="center"
							>
								<Grid item>
									<Typography variant="h6" className={classes.cardTitleWhite}>
										घर सर्वेक्षण
									</Typography>
								</Grid>

								<Grid item>
									{!loading && (
										<CSVLink
											filename={`house_survey_export.csv`}
											data={csvData}
										>
											<Button
												type="primary"
												icon="download"
												size={"default"}
												style={{ marginRight: 10 }}
											>
												Export to Excel
											</Button>
										</CSVLink>
									)}
									<Link to="/survey-by-house/add">
										<Fab
											color="primary"
											aria-label="Add"
											className={classes.fab}
										>
											<AddIcon />
										</Fab>
									</Link>
								</Grid>
							</Grid>
						</CardHeader>
						<CardBody>
							{loading ? (
								<Grid
									container
									direction="row"
									justify="center"
									alignItems="center"
								>
									<Grid item>
										<ClipLoader
											sizeUnit={"px"}
											size={84}
											color={"#00ACC1"}
											loading={loading}
										/>
									</Grid>
								</Grid>
							) : (
								<ListHouse
									houses={houses}
									wardNumber={wardNumber}
									houseNumber={houseNumber}
									ownerName={ownerName}
									handleChange={this.handleChange}
								/>
							)}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

const styles = (theme) => ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
			color: "rgba(255,255,255,.62)",
			margin: "0",
			fontSize: "14px",
			marginTop: "0",
			marginBottom: "0",
		},
		"& a,& a:hover,& a:focus": {
			color: "#FFFFFF",
		},
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
			color: "#777",
			fontSize: "65%",
			fontWeight: "400",
			lineHeight: "1",
		},
	},
	root: {
		width: "100%",
		marginTop: theme.spacing.unit * 3,
	},
	table: {
		minWidth: 500,
	},
	tableWrapper: {
		overflowX: "auto",
	},
	fab: {
		// float: "right"
	},
});

export default withStyles(styles)(index);
