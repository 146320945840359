import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
	birthCertificate,
	birthCertificateQuestion,
	childLaborQuestion,
	childLabour,
	childLabourQuestion,
	childVaccine,
	childVaccineQuestion,
	deliveryFromDoctor,
	deliveryFromDoctorQuestion,
	earlyMarriage,
	earlyMarriageQuestion,
	govHospitalName,
	govHospitalNameQuestion,
	illTreament,
	illTreamentQuestion,
	pregnanctCheck,
	pregnanctCheckQuestion,
	pregnantNutrition,
	pregnantNutritionQuestion,
	pregnantTetanus,
	pregnantTetanusQuestion,
	discriminationQuestion,
	discrimination,
	handWash,
	handWashOption,
	// alcoholUsage,
	// alcoholUsageOption,
	// alcoholUsageCount,
	// alcoholUsageCard,
	// alcoholUsageCardOption,
	// effectiveFromCard,
	// effectiveFromCardOption,
	// alcoholUsageExpense,
	// problemFromAlcohol,
	// problemFromAlcoholOption,
	// deathFromAlcohol,
	// deathFromAlcoholOption,
	// deathCount,
	returnFromAbroad,
	returnFromAbroadOption,
	stayedYearAbroad,
	familyPlanningOption,
	familyPlanning
} from "../../../../variable/houseSurvey";
import TextField from "../../../common/frequent/TextField";
import RadioButton from "../../../common/frequent/RadioButton";
import BoxBorder from "../../../common/frequent/BoxBorder";
import Selection from "../../../common/frequent/Selection";
import CheckBox from "../../../common/frequent/CheckBox";
import MultipleSelect from "../../../common/frequent/MultipleSelect";

class WomenChild extends Component {
	handleChange = (name, value) => {
		console.log("WC", name, value);
		this.props.handleChange(`womenChild.${name}`, value);
	};
	render() {
		const { womenChild } = this.props;
		const errors = this.props.errors && this.props.errors.errors;
		return (
			<Grid container>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={birthCertificateQuestion}
						name="birthCertificate"
						radioLists={birthCertificate}
						value={womenChild.birthCertificate}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childVaccineQuestion}
						name="childVaccine"
						radioLists={childVaccine}
						value={womenChild.childVaccine}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={deliveryFromDoctorQuestion}
						name="deliveryFromDoctor"
						radioLists={deliveryFromDoctor}
						value={womenChild.deliveryFromDoctor}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnantNutritionQuestion}
						name="pregnantNutrition"
						radioLists={pregnantNutrition}
						value={womenChild.pregnantNutrition}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnanctCheckQuestion}
						name="pregnanctCheck"
						radioLists={pregnanctCheck}
						value={womenChild.pregnanctCheck}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLaborQuestion}
						name="childLabor"
						radioLists={childLabour}
						value={womenChild.childLabor}
					/>
				</Grid>{" "}
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={childLabourQuestion}
						name="childLabour"
						radioLists={childLabour}
						value={womenChild.childLabour}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={earlyMarriageQuestion}
						name="earlyMarriage"
						radioLists={earlyMarriage}
						value={womenChild.earlyMarriage}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={pregnantTetanusQuestion}
						name="pregnantTetanus"
						radioLists={pregnantTetanus}
						value={womenChild.pregnantTetanus}
					/>
				</Grid>
				<Grid container>
					<Selection
						question={illTreamentQuestion}
						name="illTreament"
						selectList={illTreament}
						value={womenChild.illTreament}
						isVisible
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible={womenChild.illTreament === "public_hospita"}
						question={govHospitalNameQuestion}
						name="govHospitalName"
						radioLists={govHospitalName}
						value={womenChild.govHospitalName}
						other={womenChild.govHospitalNameOther}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={discriminationQuestion}
						name="discrimination"
						radioLists={discrimination}
						value={womenChild.discrimination}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={handWash}
						name="soapHandwash"
						radioLists={handWashOption}
						value={womenChild.soapHandwash}
					/>
				</Grid>
				{/* <Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={alcoholUsage}
						name="anyAlchoholUsage"
						radioLists={alcoholUsageOption}
						value={womenChild.anyAlchoholUsage}
					/>
				</Grid> */}
				{/* <BoxBorder
					isVisible={
						womenChild.anyAlchoholUsage &&
						womenChild.anyAlchoholUsage == "alcohol_yes"
					}
				>
					<Grid container>
						<TextField
							error={errors && errors.houseCount}
							question={alcoholUsageCount}
							name="count"
							type="number"
							placeholder=""
							value={womenChild.count}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid> */}
				{/* <Grid container>
						<RadioButton
							handleChange={this.handleChange}
							isVisible
							question={alcoholUsageCard}
							name="alcoholCard"
							radioLists={alcoholUsageCardOption}
							value={womenChild.alcoholCard}
						/>
					</Grid> */}
				{/* <Grid container>
						<RadioButton
							handleChange={this.handleChange}
							isVisible={
								womenChild.alcoholCard &&
								womenChild.alcoholCard == "alcohol_card_y"
							}
							question={effectiveFromCard}
							name="effectiveOfAlcoholCard"
							radioLists={effectiveFromCardOption}
							value={womenChild.effectiveOfAlcoholCard}
						/>
					</Grid> */}
				{/* <Grid container>
						<TextField
							error={errors && errors.houseCount}
							question={alcoholUsageExpense}
							name="yearlyExpenseInAlcohol"
							type="number"
							placeholder=""
							value={womenChild.yearlyExpenseInAlcohol}
							isVisible
							handleChange={this.handleChange}
						/>
					</Grid> */}
				{/* <Grid container>
						<CheckBox
							question={problemFromAlcohol}
							isVisible
							checkList={problemFromAlcoholOption}
							value={womenChild.issueInFamilyFromAlcohol}
							name="issueInFamilyFromAlcohol"
							handleCompChange={this.handleChange}
							other={womenChild.issueInFamilyFromAlcoholOther}
						/>
					</Grid> */}
				{/* <Grid container>
						<MultipleSelect
							question={problemFromAlcohol}
							name="issueInFamilyFromAlcohol"
							value={womenChild.issueInFamilyFromAlcohol}
							tagLists={problemFromAlcoholOption}
							isVisible
							handleChange={this.handleChange}
							other={womenChild.issueInFamilyFromAlcoholOther}
						/>
					</Grid> */}
				{/* <Grid container>
						<RadioButton
							handleChange={this.handleChange}
							isVisible
							question={deathFromAlcohol}
							name="anyDeathFromAlcohol"
							radioLists={deathFromAlcoholOption}
							value={womenChild.anyDeathFromAlcohol}
						/>
					</Grid> */}
				{/* <Grid container>
						<TextField
							// error={errors && errors.houseCount}
							question={deathCount}
							name="deathCount"
							type="number"
							placeholder=""
							value={womenChild.deathCount}
							isVisible={
								womenChild.anyDeathFromAlcohol &&
								womenChild.anyDeathFromAlcohol == "death_yes"
							}
							handleChange={this.handleChange}
						/>
					</Grid> */}
				{/* </BoxBorder> */}
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={returnFromAbroad}
						name="returnFromAbroad"
						radioLists={returnFromAbroadOption}
						value={womenChild.returnFromAbroad}
					/>
				</Grid>
				<Grid container>
					<TextField
						// error={errors && errors.houseCount}
						question={stayedYearAbroad}
						name="abroadReturnYear"
						type="number"
						placeholder=""
						value={womenChild.abroadReturnYear}
						isVisible={
							womenChild.returnFromAbroad &&
							womenChild.returnFromAbroad == "foreign_3_yes"
						}
						handleChange={this.handleChange}
					/>
				</Grid>
				<Grid container>
					<RadioButton
						handleChange={this.handleChange}
						isVisible
						question={familyPlanning}
						name="familyPlanning"
						radioLists={familyPlanningOption}
						value={womenChild.familyPlanning}
					/>
				</Grid>
			</Grid>
		);
	}
}
WomenChild.propTypes = {
	classes: PropTypes.object.isRequired
};

export default WomenChild;
