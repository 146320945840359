import axios from "axios";
import {
  ADVANCE_LOAD,
  ADVANCE_SEARCH,
  CLEAR_DATA_ADVANCE,
  GET_DEATHSEARCH,
  GET_FAMILY_BY_PHONE,
  GET_HOUSE_DETAIL,
  GET_MEMBER,
  HOUSE_LOADING,
  SET_LOADING,
  ADVANCE_CUSTOM_SEARCH,
} from "./types";

export const clearAdvancedSearchData = (search) => (dispatch) => {
  dispatch({
    type: CLEAR_DATA_ADVANCE,
    payload: [],
  });
};

export const advanceSearchHouse = (search) => (dispatch) => {
  dispatch({
    type: ADVANCE_LOAD,
    payload: true,
  });

  axios
    .post(`/api/advancedsearch/advanceSearchHouse`, search)
    .then((res) => {
      dispatch({
        type: ADVANCE_SEARCH,
        payload: res.data,
        isHouse: true,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const advanceCustomSearch = (result) => (dispatch) => {
  // dispatch({
  // 	type: ADVANCE_LOAD,
  // 	payload: true
  // });
  axios
    .post(`/api/advancedsearch/aboardCustomResult`, result)
    .then((res) => {
      dispatch({
        type: ADVANCE_CUSTOM_SEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const advanceSearchFamily = (search) => (dispatch) => {
  dispatch({
    type: ADVANCE_LOAD,
    payload: true,
  });

  axios
    .post(`/api/advancedsearch/advanceSearchFamily`, search)
    .then((res) => {
      dispatch({
        type: ADVANCE_SEARCH,
        payload: res.data,
        isHouse: false,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const getMemberByName = (name) => (dispatch) => {
  let memberName = name.name;
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  axios
    .get(`/api/searchBy/name/${memberName}`)
    .then((res) => {
      dispatch({
        type: GET_FAMILY_BY_PHONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const clearMemberList = () => (dispatch) => {
  dispatch({
    type: GET_FAMILY_BY_PHONE,
    payload: [],
  });
};
export const getMemberByPhone = (phone) => (dispatch) => {
  let memberPhone = phone.phone;
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  axios
    .get(`/api/searchBy/phone/${memberPhone}`)
    .then((res) => {
      dispatch({
        type: GET_FAMILY_BY_PHONE,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const getFamilyById = (id) => (dispatch) => {
  // dispatch({
  // 	type: SET_LOADING,
  // 	payload: true
  // });
  axios
    .get(`/api/detail/family/${id}`)
    .then((res) => {
      dispatch({
        type: GET_MEMBER,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const getHouseDetail = (houseNumber) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  axios
    .get(`/api/searchhouse/housesearch/${houseNumber}`)
    .then((res) => {
      dispatch({
        type: GET_HOUSE_DETAIL,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const getHouseByHouseNumber = (houseNumber) => (dispatch) => {
  dispatch({
    type: HOUSE_LOADING,
    payload: true,
  });
  axios
    .get(`/api/searchhouse/housesearch/${houseNumber}`)
    .then((res) => {
      dispatch({
        type: GET_HOUSE_DETAIL,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const getHouseByHouseId = (houseId) => (dispatch) => {
  dispatch({
    type: HOUSE_LOADING,
    payload: true,
  });
  axios
    .get(`/api/searchhouseById/housesearchId/${houseId}`)
    .then((res) => {
      dispatch({
        type: GET_HOUSE_DETAIL,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};

export const clearHouseDetail = () => (dispatch) => {
  dispatch({
    type: GET_HOUSE_DETAIL,
    payload: {},
  });
};

export const getDeathSearchResult = (wardData) => (dispatch) => {
  // {
  //     "house":{
  //          "wardNumber": 1
  //     }
  //    }
  // to get data count for wardNumber 1
  axios
    .post("/api/deathsearch/", wardData)
    .then((res) => {
      dispatch({
        type: GET_DEATHSEARCH,
        payload: res.data,
      });
    })
    .catch((err) => {
      // dispatch({
      // 	type: GET_ERRORS,
      // 	payload: err.response.data
      // });
    });
};
