import React from "react";
import { Table } from "antd";
import Axios from "axios";
import ReactHTMLTableToExcel from "./ReactHTMLTableToExcel";
export default class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
    };
  }

  componentDidMount() {
    const { category } = this.props.match.params;
    Axios.get(`/api/${category}/report`).then((res) => {
      this.setState({
        columns: res.data.mapColumn,
        data: res.data.data,
      });
    });
  }

  componentDidUpdate() {
    var x = document.getElementsByClassName("ant-table-content")[0];
    x.id = "ant-table-fixed";
  }

  render() {
    const { columns, data } = this.state;
    const { category } = this.props.match.params;

    return (
      <div container style={{ overflow: "scroll" }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="ant-table-fixed"
          filename={`${category}`}
          sheet="student"
          buttonText="Download as XLS"
        />
        <Table
          id="table-to-xls"
          columns={columns}
          dataSource={data}
          bordered
          size="middle"
          scroll={{ x: 500, y: 500 }}
          pagination={false}
        />
      </div>
    );
  }
}
